import { ArrowTopRightOnSquareIcon, PencilIcon } from '@heroicons/react/24/outline'
import { ClockIcon, RectangleGroupIcon, ViewColumnsIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useWebSocket from 'react-use-websocket'

import DevTimeTracker from '../components/dev-time-tracker'
import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'
import Tabs from '../components/tabs'

import { api } from '../api.service'
import AdminKanban from './admin-kanban'
import ProjectSummary from './admin-project-summary'

const TABS = [
    { hash: '', name: 'Summary' },
    { hash: '#board', name: 'Tasks', icon: ViewColumnsIcon },
    { hash: '#tracker', name: 'Dev Time', icon: ClockIcon },
]

export default function AdminProject() {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(TABS.findIndex((d) => d.hash === location.hash))
    const [project, setProject] = useState(null)

    useEffect(() => {
        loadData()
    }, [params.id])

    const loadData = () => {
        api(`${process.env.REACT_APP_API_URL}/admin/project/${params.id}`).then((x) => setProject(x))
    }

    const { sendMessage, lastMessage, readyState } = useWebSocket(process.env.REACT_APP_WS_URL)
    useEffect(() => {
        if (lastMessage?.data?.includes('project') && lastMessage.data?.includes(params.id)) {
            loadData()
        }
    }, [lastMessage])

    if (!project) return <Loading></Loading>

    return (
        <>
            <header className='relative bg-white border-b isolate border-slate-200'>
                <div className='absolute inset-0 overflow-hidden -z-10' aria-hidden='true'>
                    <div className='absolute -mt-16 opacity-50 left-16 top-full transform-gpu blur-3xl xl:left-1/2 xl:-ml-80'>
                        <div
                            className={`aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[${project.colours[0] ?? '#80ff93'}]`}
                            style={{
                                clipPath: 'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                            }}
                        />
                    </div>
                    <div className='absolute inset-x-0 bottom-0 h-px bg-gray-900/5' />
                </div>
                <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
                    <div className='flex items-center justify-between mx-auto gap-x-2 lg:mx-0 lg:max-w-none'>
                        <div className='flex'>
                            {/* ?Logo && Name */}
                            <div className='flex items-center gap-x-2'>
                                {project.logo && <ImageViewer className='h-12' image={project.logo}></ImageViewer>}
                                <h1>
                                    <div className='mt-1 text-xl font-semibold leading-8 text-gray-900'>{project.projectName ?? 'Untitled Project'}</div>
                                </h1>
                            </div>

                            <Tabs
                                tabs={TABS}
                                activeIdx={activeTab}
                                onChange={(val) => {
                                    setActiveTab(val)
                                    navigate(TABS[val]?.hash ?? '')
                                }}
                            />
                        </div>
                        {/* ?Link && EditBtn */}
                        <div className='flex items-center gap-x-2 md:gap-x-4'>
                            {project.url && (
                                <a href={project.url} className='p-2 text-sm font-semibold text-gray-700 bg-gray-200 rounded-md shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200'>
                                    <ArrowTopRightOnSquareIcon className='w-5 mx-2 my-auto' />
                                </a>
                            )}
                            <a href={'/admin/manage-project/' + params.id} className='p-2 text-sm font-semibold text-white bg-green-600 rounded-md shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600'>
                                <PencilIcon className='w-5 mx-2 my-auto'></PencilIcon>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
            <div>
                {activeTab === 0 && <ProjectSummary project={project} />}
                {activeTab === 1 && <AdminKanban id={params.id} />}
                {activeTab === 2 && <DevTimeTracker id={params.id} />}
            </div>
        </>
    )
}
