import { useNavigate, useParams } from 'react-router-dom';

import CodeEditor from '../components/code-editor';
import PageHeader from '../components/page-header';
import { CheckboxField, TextAreaField } from './fields';

import {
    SandpackCodeEditor, SandpackLayout, SandpackPreview, SandpackProvider, useSandpack
} from "@codesandbox/sandpack-react";
import Button from '../components/button';
import TextField from './fields/text-field';
import FormWrapper from './form-wrapper';

export default function ComponentForm(props) {
    const params = useParams()
    let id = params.id ?? props.id
    const navigate = useNavigate()

    return (
        <div>
            <PageHeader headline='Manage Component'></PageHeader>
            <FormWrapper url='admin/component' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <div className=""></div>
                        <TextField label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                        <TextField label='Category' value={values.category} onChange={(val) => setValues({ ...values, category: val })}></TextField>
                        <TextField label='Tags' value={values.tags} onChange={(val) => setValues({ ...values, tags: val })}></TextField>
                        <TextAreaField label='Description' value={values.description} onChange={(val) => setValues({ ...values, description: val })} />
                        <TextAreaField label='Tips' value={values.tips} onChange={(val) => setValues({ ...values, tips: val })} />

                        <div className='mt-5 h-96'>
                            <CodeEditor value={values.code} onChange={(val) => setValues({ ...values, code: val })}></CodeEditor>
                        </div>

                        <CheckboxField label="Display Sandbox" value={values.showSandbox} onChange={(val) => setValues({ ...values, showSandbox: val })} />

                       {values.showSandbox &&  <div className="mt-5">
                            <SandpackProvider
                                template="react-ts"
                                theme="dark"
                                customSetup={{ 
                                    dependencies: { 
                                        "@headlessui/react": "^1.7.4",
                                        "@heroicons/react": "^2.0.13"
                                    }
                                  }}
                                options={{
                                    externalResources: ["https://cdn.tailwindcss.com"]
                                }}
                                files={values.sandbox?.files ?? {
                                    '/App.tsx': `import Component from './Component.tsx'
export default function App() {
    return <Component />
}`,
                                    '/Component.tsx': `export default function Component() {
    return <h1>Hello World</h1>
}`}} >


                                <Sandbox onChange={(val) => setValues({ ...values, sandbox: { files: val } })}></Sandbox>

                            </SandpackProvider>
                        </div>}
                    </>
                )}
            </FormWrapper>
        </div>
    )
}


const Sandbox = ({ onChange }) => {
    const { sandpack } = useSandpack();

    return <div>
        <SandpackLayout className="h-[1000px] flex-col">
            <SandpackCodeEditor style={{ height: '600px' }} showLineNumbers={true} showRunButton={false} />
            <SandpackPreview showNavigator style={{ height: '600px' }} showOpenInCodeSandbox={false}>
            </SandpackPreview>
        </SandpackLayout>
        <Button className="mt-5" text="Save Sandbox Changes" onClick={() => onChange(sandpack.files)} />

    </div>

}