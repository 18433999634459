import { useState } from 'react'

export default function StatusBox({ status = 0, statuses, change, className }) {
    const [open, setOpen] = useState(false)

    return (
        <div onClick={() => setOpen(!open)} className={`overflow-visible relative cursor-pointer mx-2 flex-1 bg-${statuses?.find((d) => d.value == status)?.colour ?? 'gray-200'} ${className}`}>
            <div className='h-full p-3 text-lg font-semibold text-center text-white align-middle'>{statuses.find((d) => d.value == status)?.text ?? 'N/A'}</div>

            {open && (
                <div className='relative w-full bottom-3'>
                    <div className='absolute z-40 w-full overflow-hidden bg-white shadow rounded-b-xl'>
                        {statuses.map((x) => (
                            <div className='p-2 text-lg border-b hover:bg-gray-50 text-neutral-600' onClick={() => change(x.value)}>
                                {x.text}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
