import { useEffect, useState } from 'react';

import { Card, DonutChart, Flex, Metric, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Text, Title } from "@tremor/react";

import { DateRangePicker } from "@tremor/react";
import Loading from '../components/loading';

import { MapPinIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { api } from '../api.service';
import Tabs from '../components/tabs';
import { PROJECT_STAGE } from './admin-project-statuses';

const getStats = (data, status) => {
    if (status >= -1 && status <= 2) {
        return data.filter(project => {
            return project['projectStage'] <= status || project['projectStage'] === undefined
        })
    } else if (status > 2 && status < 5) {
        return data.filter(project => {
            return project['projectStage'] <= 4 && project['projectStage'] > 2
        })
    } else {
        return data.filter(project => {
            return project['projectStage'] > 4
        })
    }
}


const getStage = (stage) => {
    return PROJECT_STAGE.find(x => x.value == stage).text
}

const getUniqueSources = (data) => {
    return [...new Set(data.map(project => project.source))]
}

const getUniqueLocations = (data) => {
    return [...new Set(data.map(project => project.country))]
}

const TABS = [
    { name: "Source", icon: UserCircleIcon },
    { name: "Location", icon: MapPinIcon }
]

export default function AdminDashboard() {
    const [data, setData] = useState(null)
    const [activeTab, setActiveTab] = useState(0)
    const [value, setValue] = useState({
        from: new Date(2023, 1, 1),
        to: new Date(),
        2: "at"
    });
    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/project`).then((x) => setData(x.filter(x => !x.isArchived)))

    }, [])

    const filteredData = data && data.filter((project) => moment(new Date(project.dateIntroCall)) >= value[0] || !value[0])

    if (!data) return <Loading></Loading>

    return (
        <>
            <main className="max-w-6xl pt-20 mx-auto ">
                <Title>Verticode Dashboard</Title>
                <div className="flex">
                    <Text className="flex-1">An overview of the Verticode projects.</Text>
                    <DateRangePicker
                        className="max-w-md mx-auto"
                        value={value}
                        onValueChange={setValue}
                        color="rose"
                        options={[{ value: 'at', text: 'All Time', startDate: null, endDate: null },
                        { value: 'tm', text: 'This Month', startDate: moment(new Date()).startOf('month').toDate() },
                        { value: 'tq', text: 'Last 30 Days', startDate: moment(new Date()).startOf('day').add(-30, 'days').toDate() },
                        { value: 'ltq', text: 'Last 60 Days', startDate: moment(new Date()).startOf('day').add(-60, 'days').toDate() },
                        { value: 'ytd', text: 'YTD', startDate: moment(new Date()).startOf('year').toDate() },
                        { value: 'year', text: 'Year', startDate: moment(new Date()).add(-1, 'years').toDate() },
                        ]}
                    >
                    </DateRangePicker>
                </div>

                <div className="grid grid-cols-3 gap-6 mt-6">
                    <Card className="max-w-lg mx-auto">
                        <Flex alignItems="start">
                            <div>
                                <Text>Prospects</Text>
                                <Metric>{getStats(data, 2).length}</Metric>
                            </div>
                        </Flex>
                    </Card>
                    <Card className="max-w-lg mx-auto">
                        <Flex alignItems="start">
                            <div>
                                <Text>Open Projects</Text>
                                <Metric>{getStats(data, 3).length}</Metric>
                            </div>
                        </Flex>
                    </Card>
                    <Card className="max-w-lg mx-auto">
                        <Flex alignItems="start">
                            <div>
                                <Text>Finished Projects</Text>
                                <Metric>{getStats(data, 5).length}</Metric>
                            </div>
                        </Flex>
                    </Card>
                </div>

                <div className='grid grid-cols-2 gap-6 mt-6'>
                    <Card>
                        <Title>Breakdown by source</Title>
                        <div className='flex'>
                            <CreateSummaryDonut data={filteredData} breakdown='source' />
                            <CreateCostDonut data={filteredData} breakdown='source' />
                        </div>

                    </Card>

                    <Card>
                        <Title>Breakdown by location</Title>
                        <div className='flex'>
                            <CreateSummaryDonut data={filteredData} breakdown='location' />
                            <CreateCostDonut data={filteredData} breakdown={'location'} />
                        </div>
                    </Card>
                </div>

                <Card className='mt-6'>
                    <Tabs tabs={TABS} activeIdx={activeTab} onChange={(val) => setActiveTab(val)}></Tabs>
                    <div className="mt-6">
                        {<DataTableOuter activeTab={activeTab} filteredData={filteredData} />}
                    </div>
                </Card>
            </main>
        </>
    )
}

function DataTableOuter({ activeTab, filteredData }) {
    return (
        <>
            <Title className='ml-3'>Breakdown by {activeTab === 0 ? 'Source' : 'Location'}</Title>
            <Table className='mt-5'>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>{activeTab === 0 ? 'Source' : 'Location'}</TableHeaderCell>
                        <TableHeaderCell>Total</TableHeaderCell>
                        <TableHeaderCell>Pending</TableHeaderCell>
                        <TableHeaderCell>Signed</TableHeaderCell>
                        <TableHeaderCell>Declined</TableHeaderCell>
                        <TableHeaderCell>Average Price / £</TableHeaderCell>
                        <TableHeaderCell>Average Cost / £</TableHeaderCell>
                        <TableHeaderCell>Average Gross / £</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activeTab === 0 && getUniqueSources(filteredData).map((source) => (
                        <DataTableInner data={filteredData.filter((project) => project.source === source)} breakdown={source} activeTab={activeTab} />
                    ))}
                    {activeTab === 1 && getUniqueLocations(filteredData).map((location) => (
                        <DataTableInner data={filteredData.filter((project) => project.location === location)} breakdown={location} activeTab={activeTab} />
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

function DataTableInner({ data, breakdown, activeTab }) {
    const [open, setOpen] = useState(false)

    const calculateAveragePrice = (data) => {
        return (data.map(project => project.price).reduce((acc, cur) => acc + (cur || 0), 0)) / (data.map(project => project.price).length)
    }

    const calculateAverageCost = (data) => {
        return (data.map(project => project.customerAcquisitionCost).reduce((acc, cur) => acc + (cur || 0), 0)) / (data.map(project => project.customerAcquisitionCost).length)
    }

    return (
        <>
            <TableRow className={`cursor-pointer hover:bg-green-700 hover:text-white ${open ? 'bg-green-700 text-white' : null}`} key={breakdown} onClick={() => setOpen(!open)}>
                <TableCell>{breakdown ? breakdown : 'Not Available'}</TableCell>
                <TableCell>{data.length}</TableCell>
                <TableCell>{data.filter(project => project.projectStage > -1 && project.projectStage < 3).length}</TableCell>
                <TableCell>{data.filter(project => project.projectStage >= 3).length} ({((data.filter(project => project.projectStage >= 3).length) / data.length * 100).toFixed(0)}%)</TableCell>
                <TableCell>{data.filter(project => project.projectStage === -1).length}</TableCell>
                <TableCell>
                    {calculateAveragePrice(data).toFixed(2) || '-'}
                </TableCell>
                <TableCell>
                    {calculateAverageCost(data).toFixed(2) || '-'}
                </TableCell>
                <TableCell>
                    {(calculateAveragePrice(data) - calculateAverageCost(data)).toFixed(2) || '-'}
                </TableCell>
            </TableRow>

            {open &&
                <TableRow>
                    <TableCell colSpan={8}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell>Project Name</TableHeaderCell>
                                    <TableHeaderCell>Stage</TableHeaderCell>
                                    <TableHeaderCell>{activeTab !== 0 ? 'Source' : 'Location'}</TableHeaderCell>
                                    <TableHeaderCell>Intro Call Date</TableHeaderCell>
                                    <TableHeaderCell>Price / £</TableHeaderCell>
                                    <TableHeaderCell>Cost / £</TableHeaderCell>
                                    <TableHeaderCell>Gross / £</TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map(project => (
                                    <TableRow className='bg-gray-100' onClick={() => setOpen(!open)}>
                                        <TableCell>
                                            <Text>{project.projectName}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{getStage(project.projectStage)}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{activeTab !== 0 ? project.source || '-' : project.location || '-'}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{project.dateIntroCall ? project.dateIntroCall.split('T')[0].split('-').reverse().join('/') : 'N/A'}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{project.price || '-'}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{project.customerAcquisitionCost || '-'}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{(project.price - project.customerAcquisitionCost) || '-'}</Text>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            }
        </>
    )
}

function CreateSummaryDonut({ data, breakdown }) {
    let sortedData
    if (breakdown === 'source') {
        sortedData = getUniqueSources(data).map(source => {
            return {
                source: source || 'Unknown',
                total: data.filter(project => project.source === source).length
            }
        })
    } else {
        sortedData = getUniqueLocations(data).map(location => {
            return {
                location: location || 'Unknown',
                total: data.filter(project => project.country === location).length
            }
        })
    }

    const valueFormatter = (number) => `${number} projects`
    return (
        <DonutChart
            className='mt-6'
            valueFormatter={valueFormatter}
            data={sortedData}
            category='total'
            index={breakdown === 'source' ? 'source' : "location"}
            colors={["amber", "red", "pink", "orange", "fuchsia", "gray", "green", "indigo", "lime", "neutral", "emerald", "rose", "purple", "blue", "cyan", "sky", "slate"]}
        />
    )
}

function CreateCostDonut({ data, breakdown }) {
    let sortedData

    if (breakdown === 'source') {
        sortedData = getUniqueSources(data).map(source => {
            return {
                source: source || 'Unknown',
                total: data.filter(project => project.source === source).map(project => project.price - project.customerAcquisitionCost).reduce((acc, cur) => acc + (cur || 0), 0)
            }
        })
    } else {
        sortedData = getUniqueLocations(data).map(location => {
            return {
                location: location || 'Unknown',
                total: data.filter(project => project.country === location).map(project => project.price - project.customerAcquisitionCost).reduce((acc, cur) => acc + (cur || 0), 0)
            }
        })
    }

    const valueFormatter = (number) => `£${number}`

    return (
        <DonutChart
            className='mt-6'
            valueFormatter={valueFormatter}
            data={sortedData}
            category='total'
            index={breakdown === 'source' ? 'source' : 'location'}
            colors={["indigo", "blue", "cyan", "emerald", "fuchsia", "gray", "green", "amber", "lime", "neutral", "orange", "pink", "purple", "red", "rose", "sky", "slate"]}
        />
    )
}