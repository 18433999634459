import { TrashIcon } from '@heroicons/react/24/outline'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '../components/button'
import PageHeader from '../components/page-header'

import SelectField from './fields/select-field'
import TextField from './fields/text-field'
import FormWrapper from './form-wrapper'

function reducer(state, action, payload) {
    switch (action) {
        case 'ADD_FOLDER':
            return { ...state, folders: [...(state.folders || []), {}] }

        case 'DELETE_FOLDER':
            return { ...state, folders: state.folders.filter((_, i) => i !== payload.folderIdx) }

        case 'SET_PATH':
            return { ...state, folders: state.folders.map((x, i) => (i === payload.folderIdx ? { ...x, path: payload.val } : x)) }

        case 'SET_FOLDER_NAME':
            return { ...state, folders: state.folders.map((x, i) => (i === payload.folderIdx ? { ...x, name: payload.val } : x)) }

        case 'ADD_FILE':
            return { ...state, folders: state.folders.map((x, i) => (i === payload.folderIdx ? { ...x, files: [...(x.files || []), {}] } : x)) }

        case 'SET_FILE':
            return { ...state, folders: state.folders.map((x, i) => (i === payload.folderIdx ? { ...x, files: x.files.map((y, j) => (j === payload.fileIdx ? { ...y, file: payload.val } : y)) } : x)) }

        case 'DELETE_FILE':
            return { ...state, folders: state.folders.map((x, i) => (i === payload.folderIdx ? { ...x, files: x.files.filter((_, j) => j !== payload.fileIdx) } : x)) }

        default:
            return state
    }
}

export default function BoilerplateForm(props) {
    const params = useParams()
    let id = params.id ?? props.id
    const navigate = useNavigate()

    return (
        <>
            <PageHeader headline='Manage Boilerplate'></PageHeader>

            <FormWrapper url='admin/boilerplate' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <div className='grid grid-cols-1 gap-4 sm:grid-cols-4'>
                            <TextField label='Title' value={values.title} onChange={(val) => setValues({ ...values, title: val })} className='sm:col-span-3'></TextField>
                            <TextField label='Version' value={values.version} onChange={(val) => setValues({ ...values, version: val })}></TextField>
                        </div>

                        {values.folders?.map((folder, folderIdx) => (
                            <div key={folderIdx} className='p-5 my-5 border rounded'>
                                <div className='flex flex-row flex-1'>
                                    <div className='flex items-end mx-2 mb-1 text-2xl leading-7'>/</div>
                                    <TextField label='Directory Path' value={folder?.path ?? ''} onChange={(val) => setValues(reducer(values, 'SET_PATH', { folderIdx, val }))} className='flex-1'></TextField>
                                    <div className='flex items-end mx-2 mb-1 text-2xl leading-7'>/</div>
                                    <TextField label='Directory Name' value={folder.name} onChange={(val) => setValues(reducer(values, 'SET_FOLDER_NAME', { folderIdx, val }))}></TextField>
                                    <div className='flex items-end' onClick={() => setValues(reducer(values, 'DELETE_FOLDER', { folderIdx }))}>
                                        <TrashIcon className='w-6 h-6 mx-4 my-2 text-amber-600' />
                                    </div>
                                </div>
                                <hr className='m-4' />

                                {folder.files?.map((file, fileIdx) => (
                                    <div key={fileIdx} className='flex'>
                                        <SelectField optionListName={'code-templates'} className='w-full' label={`File ${fileIdx + 1}`} value={file?.file ?? null} onChange={(val) => setValues(reducer(values, 'SET_FILE', { folderIdx, fileIdx, val }))} />
                                        <div className='flex items-end' onClick={() => setValues(reducer(values, 'DELETE_FILE', { folderIdx, fileIdx }))}>
                                            <TrashIcon className='w-6 h-6 mx-4 my-2 text-amber-600' />
                                        </div>
                                    </div>
                                ))}

                                <div className='mt-5'>
                                    <Button text='Add File' onClick={() => setValues(reducer(values, 'ADD_FILE', { folderIdx }))}></Button>
                                </div>
                            </div>
                        ))}

                        <div>
                            <Button text='Add Directory' onClick={() => setValues(reducer(values, 'ADD_FOLDER'))}></Button>
                        </div>
                    </>
                )}
            </FormWrapper>
        </>
    )
}
