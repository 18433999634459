import { api_file } from '../../api.service'

export default function FileField({ label, value, onChange, className, inputClass, labelClass, placeholder, onBlur, endpoint }) {
    const uploadFile = (file) => {
        const formData = new FormData()
        formData.append('file', file)
        api_file(`${process.env.REACT_APP_API_URL}/${endpoint ? endpoint : 'admin'}/file`, formData).then((x) => onChange(x.name))
    }

    return (
        <div className={className}>
            <label>
                <div className={`block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>{label}</div>
                <div className='block text-sm font-medium text-left text-zinc-700'>
                    {value && (
                        <span>
                            <b>File Name:</b> {value}
                        </span>
                    )}
                </div>
                <input onChange={(e) => uploadFile(e.target.files[0])} type='file' name='file' className='w-0 h-0 opacity-0' />
                <div className='p-3 text-sm font-medium cursor-pointer bg-gradient-to-b from-neutral-50 to-neutral-100 text-zinc-700 hover:text-zinc-900'>Upload File</div>
            </label>
        </div>
    )
}
