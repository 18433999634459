import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../components/button'
import Confirm from '../components/confirm'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

import { api, api_delete } from '../api.service'

export default function AdminUsers() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [deleteId, setDeleteId] = useState()
    const [welcomeEmailId, setWelcomeEmailId] = useState()
    const deleteConfirm = () => {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/user/${deleteId}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/admin/user`)
                .then((x) => setData(x))
                .then((x) => setDeleteId(null))
        })
    }

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/user`).then((x) => setData(x))
    }, [])

    const sendWelcomeEmail = () => {
        api(`${process.env.REACT_APP_API_URL}/admin/send-welcome-email/${welcomeEmailId}`).then((x) => {
            setWelcomeEmailId(null)
            alert('Welcome Email Sent')
        })
    }

    if (!data) return <Loading></Loading>

    return (
        <>
            <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm()}></Confirm>
            <Confirm open={!!welcomeEmailId} cancel={() => setWelcomeEmailId(false)} confirm={() => sendWelcomeEmail()}></Confirm>

            <PageHeader headline={'Users'} actions={<Button text='Add User' onClick={() => navigate('/admin/manage-user/new')}></Button>}></PageHeader>
            <div className='flex'>
                <div className='w-full max-w-6xl pb-20 mx-auto my-10'>
                    <div>
                        <Table
                            data={data.map((x) => {
                                return {
                                    ...x,
                                    welcomeEmail: (
                                        <span className='underline cursor-pointer' onClick={() => setWelcomeEmailId(x._id)}>
                                            Send Welcome Email
                                        </span>
                                    ),
                                    edit: (
                                        <span className='underline cursor-pointer' onClick={() => navigate(`/admin/manage-user/${x._id}`)}>
                                            Edit
                                        </span>
                                    ),
                                    deleteCell: (
                                        <span className='underline cursor-pointer' onClick={() => setDeleteId(x._id)}>
                                            Delete
                                        </span>
                                    ),
                                }
                            })}
                            columns={[
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                },
                                {
                                    Header: 'Email',
                                    accessor: 'email',
                                },
                                {
                                    Header: 'Send Welcome Email',
                                    accessor: 'welcomeEmail',
                                },
                                {
                                    Header: 'Edit',
                                    accessor: 'edit',
                                },
                                {
                                    Header: 'Delete',
                                    accessor: 'deleteCell',
                                },
                            ]}
                        ></Table>
                    </div>
                </div>
            </div>
        </>
    )
}
