export default function CheckboxField({ label, value, onChange, className, inputClass, onBlur, labelClass, inline }) {
    return (
        <div className={className + (inline ? ' flex place-items-center' : '')}>
            {!inline && <label className={`block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>{label}</label>}
            <div className={inline ? '' : 'mt-2'}>
                <input type='checkbox' checked={value} onBlur={onBlur} onChange={(e) => onChange(e.target.checked)} className={`${inline ? 'w-7 h-7' : 'w-9 h-9'} block rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 ${inputClass}`} />
            </div>
            {inline && <label className={`ml-3 block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>{label}</label>}
        </div>
    )
}
