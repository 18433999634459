import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginCheck } from '../api.service'

import logo from '../logo.png'

export default function Login() {
    const navigate = useNavigate()
    const [values, setValues] = useState({})
    const [error, setError] = useState('')

    const submit = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ user: values }),
        })
            .then((res) => {
                return res.json()
            })
            .then((x) => {
                if (x.token) {
                    localStorage.setItem('token', x.token)
                    localStorage.setItem('user', JSON.stringify(x.user))
                    window.location.replace(x.homepage)
                } else {
                    setError('Login failed. Please check your details and try again.')
                }
            })
    }

    useEffect(() => {
        const check = async () => {
            if ((await loginCheck(`${process.env.REACT_APP_API_URL}/admin/project`))) {
                window.location.replace('/admin')
            }
            else if ((await loginCheck(`${process.env.REACT_APP_API_URL}/project`))) {
                window.location.replace('/app')
            }

        }
        check();

    }, [])

    return (
        <>
            <span className='bg-neutral-600'></span>
            <div className='flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8'>
                <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                    <img className='w-auto mx-auto h-44' src={logo} alt='Your Company' />
                    <h2 className='mt-24 text-3xl font-bold tracking-tight text-center text-green-500'>Login</h2>
                </div>

                <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
                    <div className='px-4 py-8 bg-white sm:rounded-lg sm:px-10'>
                        <div className='space-y-6'>
                            <div>
                                <label className='block text-sm font-medium text-center text-neutral-700'>{error}</label>
                            </div>
                            <div>
                                <label htmlFor='email' className='block text-sm font-medium text-neutral-700'>
                                    Email address
                                </label>
                                <div className='mt-1'>
                                    <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} id='email' name='email' type='email' autoComplete='email' required className='block w-full px-3 py-2 border rounded-md shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm' />
                                </div>
                            </div>

                            <div>
                                <label htmlFor='password' className='block text-sm font-medium text-neutral-700'>
                                    Password
                                </label>
                                <div className='mt-1'>
                                    <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })} id='password' name='password' type='password' autoComplete='current-password' required className='block w-full px-3 py-2 border rounded-md shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm' />
                                </div>
                            </div>

                            <div className='flex items-center justify-between'>
                                <div className='text-sm'>
                                    <a href='/request-reset' className='font-medium text-green-600 hover:text-green-500'>
                                        Forgot your password?
                                    </a>
                                </div>
                            </div>

                            <div>
                                <button onClick={() => submit()} type='button' className='flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-green-500 border border-transparent rounded-md shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'>
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
