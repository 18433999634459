import { PencilIcon, XMarkIcon, CheckIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { toast, ToastContainer } from 'react-toastify'
import useWebSocket from 'react-use-websocket'

import Button from '../components/button'
import Loading from '../components/loading'

import 'react-toastify/dist/ReactToastify.css'

import EditableCheckboxField from '../forms/fields/editable-checkbox'
import SelectField from '../forms/fields/select-field'
import TextAreaField from '../forms/fields/text-area-field'
import TextField from '../forms/fields/text-field'
import FormModal from '../forms/form-modal'

import { api, api_delete } from '../api.service'
import ImageField from '../forms/fields/image-upload'
import ImageViewer from '../components/image-viewer'

export default function AdminKanban({ id }) {
    const [tasks, setTasks] = useState()
    const [users, setUsers] = useState()
    const [cardEditModalId, setCardEditModalId] = useState()
    const [groupingEditModalName, setGroupingEditModalName] = useState(null)
    const [editingInputGroupingName, setEditingInputGroupingName] = useState(null)
    const [showGroupingModal, setShowGroupingModal] = useState(false)
    const [groupingFilters, setGroupingFilters] = useState([])
    const [showArchived, setShowArchived] = useState(false)
    const [groupings, setGroupings] = useState([])

    const groupingsColours = ['bg-red-200', 'bg-blue-400', 'bg-green-200', 'bg-yellow-200', 'bg-purple-400', 'bg-pink-500', 'bg-red-600', 'bg-blue-700', 'bg-green-600']

    useEffect(() => {
        if (groupings.length === 0 || groupings.filter((grouping) => !grouping.colour).length === 0) return

        if (!(groupings.length > groupingsColours.length)) {
            const takenColours = []
            const colouredGroupings = []

            groupings.forEach((grouping) => {
                if (grouping.colour) {
                    takenColours.push(grouping.colour)
                    colouredGroupings.push(grouping)
                } else {
                    const nextAvailableColour = groupingsColours.filter((colour) => !takenColours.includes(colour))[0]

                    takenColours.push(nextAvailableColour)
                    colouredGroupings.push({ ...grouping, colour: nextAvailableColour })
                }
            })

            setGroupings(colouredGroupings)
        }
    }, [groupings])

    useEffect(() => {
        if (!showGroupingModal) {
            setTimeout(() => {
                setEditingInputGroupingName(null)
                setGroupingEditModalName(null)
            }, 500)
        }
    }, [showGroupingModal])

    useEffect(() => {
        if (tasks && tasks.length > 0) {
            const existingGroupings = []
            tasks.forEach((task) => {
                if (task.grouping && task.grouping !== '' && groupings.filter((grouping) => grouping.name === task.grouping).length === 0 && existingGroupings.filter((grouping) => grouping.name === task.grouping).length === 0) {
                    existingGroupings.push({ name: task.grouping })
                }
            })

            setGroupings([...groupings, ...existingGroupings])
        }
    }, [tasks])

    let lanes = ['Parked', 'To Do', 'Development', 'Testing', 'Done', 'Archived']
    useEffect(() => {
        loadData()
        api(`${process.env.REACT_APP_API_URL}/admin/user`).then((x) => setUsers(x))
    }, [])

    const loadData = () => {
        api(`${process.env.REACT_APP_API_URL}/admin/tasks/${id}`).then((x) => setTasks(x))
    }

    const { sendMessage, lastMessage, readyState } = useWebSocket(process.env.REACT_APP_WS_URL)
    useEffect(() => {
        if (lastMessage?.data?.includes('tasks') && lastMessage.data?.includes(id)) {
            loadData()
        }
    }, [lastMessage])

    const onDragEnd = (result, v) => {
        let startingLane = result.source.droppableId.split('_')[1]
        let newLane = result.destination.droppableId.split('_')[1]
        moveCard(result.draggableId, newLane, result.destination.index)

        let _tasks = [...tasks]
        for (let [i, l] of lanes.entries()) {
            for (let [taskIndex, task] of _tasks
                .sort((a, b) => a.index - b.index)
                .filter((x) => x.lane == i)
                .entries()) {
                if (task._id == result.draggableId) {
                    task.lane = newLane
                    task.index = result.destination.index
                } else {
                    task.index = taskIndex
                    if (task.lane == startingLane && task.index >= result.source.index) {
                        task.index--
                    }
                    if (task.lane == newLane && task.index >= result.destination.index) {
                        task.index++
                    }
                }
            }
        }
        setTasks([...tasks.sort((a, b) => a.index - b.index)])
    }

    const upsertCard = (data) => {
        api(`${process.env.REACT_APP_API_URL}/admin/task/${id}`, { data })
    }
    const moveCard = (_id, lane, index) => {
        api(`${process.env.REACT_APP_API_URL}/admin/task/move/${id}/${_id}/${lane}/${index}`)
    }

    const openEditModal = (id) => {
        setCardEditModalId(id)
    }

    if (!tasks) return <Loading />
    const deleteCard = (id) => {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/task/${id}`)
    }

    const archiveTask = (id) => {
        setTasks(tasks.map((x) => (x._id == id ? { ...x, isArchived: true, lane: 5 } : x)))
    }

    // FOR FUTURE - Bring archived back to active
    // const activeTask = (id) => {
    //     setTasks(tasks.map((x) => x._id == id ? { ...x, isArchived: false, lane: 1 } : x))
    // }

    return (
        <>
            <ToastContainer position='bottom-right' autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light' />

            <FormModal open={!!cardEditModalId} setOpen={() => setCardEditModalId(null)}>
                <div className='grid items-end grid-cols-2 gap-2'>
                    <TextField
                        label='Title'
                        placeholder={'Title'}
                        value={tasks?.find((x) => x._id == cardEditModalId)?.title}
                        onChange={(val) => {
                            setTasks(tasks.map((x) => (x._id == cardEditModalId ? { ...x, title: val } : x)))
                        }}
                        onBlur={() => upsertCard(tasks.find((x) => x._id == cardEditModalId))}
                    />
                    <SelectField
                        optionListName={'users'}
                        label='Assigned To'
                        value={tasks?.find((x) => x._id == cardEditModalId)?.assignedTo?._id}
                        onChange={(val) => {
                            setTasks(tasks.map((x) => (x._id == cardEditModalId ? { ...x, assignedTo: val } : x)))
                            upsertCard({ ...tasks.find((x) => x._id == cardEditModalId), assignedTo: val })
                        }}
                    />
                </div>
                {groupings && groupings.length > 0 && (
                    <SelectField
                        options={groupings.map((grouping) => {
                            return { text: grouping.name, value: grouping.name }
                        })}
                        label='Grouping'
                        placeholder='Assign to a grouping'
                        value={tasks?.find((x) => x._id == cardEditModalId)?.grouping}
                        onChange={(val) => {
                            setTasks(tasks.map((x) => (x._id == cardEditModalId ? { ...x, grouping: val } : x)))
                            upsertCard({ ...tasks.find((x) => x._id == cardEditModalId), grouping: val })
                        }}
                    />
                )}
                <TextAreaField
                    label='Text'
                    inputClass='ring-0 shadow '
                    value={tasks?.find((x) => x._id == cardEditModalId)?.text}
                    onChange={(val) => {
                        setTasks(tasks.map((x) => (x._id == cardEditModalId ? { ...x, text: val } : x)))
                    }}
                    onBlur={() => {
                        upsertCard(tasks.find((x) => x._id == cardEditModalId))
                    }}
                />
                <div className='m-2'>
                    Acceptance criteria
                    {tasks &&
                        cardEditModalId &&
                        tasks.find((x) => x._id == cardEditModalId).acceptanceCriteria &&
                        tasks.find((x) => x._id == cardEditModalId).acceptanceCriteria.length > 0 &&
                        tasks
                            .find((x) => x._id == cardEditModalId)
                            .acceptanceCriteria.map((ac, acIndex) => {
                                return (
                                    <div className='flex items-center' key={acIndex}>
                                        <div
                                            className='pr-2'
                                            onClick={() => {
                                                setTasks(
                                                    tasks.map((x) =>
                                                        x._id == cardEditModalId
                                                            ? {
                                                                  ...x,
                                                                  acceptanceCriteria: tasks.find((x) => x._id == cardEditModalId).acceptanceCriteria.filter((ac, index) => index !== acIndex),
                                                              }
                                                            : x
                                                    )
                                                )
                                            }}
                                        >
                                            <ChevronRightIcon className='w-5 h-5' />
                                        </div>
                                        <EditableCheckboxField
                                            onBlur={() => {
                                                upsertCard(tasks.find((x) => x._id == cardEditModalId))
                                            }}
                                            onLabelChange={(val) => {
                                                setTasks(
                                                    tasks.map((x) =>
                                                        x._id == cardEditModalId
                                                            ? {
                                                                  ...x,
                                                                  acceptanceCriteria: tasks
                                                                      .find((x) => x._id == cardEditModalId)
                                                                      .acceptanceCriteria.map((ac, index) =>
                                                                          acIndex === index
                                                                              ? {
                                                                                    ...ac,
                                                                                    description: val,
                                                                                }
                                                                              : ac
                                                                      ),
                                                              }
                                                            : x
                                                    )
                                                )
                                            }}
                                            onCheckboxChange={(val) => {
                                                setTasks(
                                                    tasks.map((x) =>
                                                        x._id == cardEditModalId
                                                            ? {
                                                                  ...x,
                                                                  acceptanceCriteria: tasks
                                                                      .find((x) => x._id == cardEditModalId)
                                                                      .acceptanceCriteria.map((ac, index) =>
                                                                          acIndex === index
                                                                              ? {
                                                                                    ...ac,
                                                                                    completed: val,
                                                                                }
                                                                              : ac
                                                                      ),
                                                              }
                                                            : x
                                                    )
                                                )
                                            }}
                                            defaultLabelText={ac.description}
                                            inline
                                            checked={ac.completed}
                                        />
                                    </div>
                                )
                            })}
                    <div
                        onClick={() => {
                            setTasks(
                                tasks.map((x) =>
                                    x._id == cardEditModalId
                                        ? {
                                              ...x,
                                              acceptanceCriteria: [...tasks.find((x) => x._id == cardEditModalId).acceptanceCriteria, { completed: false, description: 'Click to edit...' }],
                                          }
                                        : x
                                )
                            )
                        }}
                        className='text-sm text-gray-500 cursor-pointer'
                    >
                        + New Criteria...
                    </div>
                </div>
                <ImageField 
                    label='Image'
                    value={tasks.find((x) => x._id == cardEditModalId)?.images}
                    onChange={(val) => {
                        setTasks(tasks.map((x) => x._id == cardEditModalId ? { ...x, images: val } : x))
                    }}
                    onBlur={() => upsertCard(tasks.find((x) => x._id == cardEditModalId))}
                />
                {tasks.find((x) => x._id == cardEditModalId)?.images && 
                    <div className='relative'>
                       {Array(tasks.find((x) => x._id == cardEditModalId)?.images).map(img => (
                           <ImageViewer image={img} className='mx-auto' />
                       ))}
                       <XMarkIcon className='absolute w-5 h-5 cursor-pointer top-2 right-2' onClick={() => {
                            upsertCard({ ...tasks.find((x) => x._id == cardEditModalId), images: null })
                       }} />
                    </div>
                }
                <TextAreaField
                    label='Details'
                    inputClass='ring-0 shadow mb-2'
                    value={tasks.find((x) => x._id == cardEditModalId)?.details}
                    onChange={(val) => {
                        setTasks(tasks.map((x) => (x._id == cardEditModalId ? { ...x, details: val } : x)))
                    }}
                    onBlur={() => upsertCard(tasks.find((x) => x._id == cardEditModalId))}
                />
                <Button text='Save changes' onClick={() => upsertCard(tasks.find((x) => x._id == cardEditModalId))}></Button>
                <div className='flex justify-between'>
                    <button
                        className='px-2 py-1 mt-4 text-sm font-normal text-white bg-red-500 rounded'
                        onClick={() => {
                            deleteCard(cardEditModalId)
                            setCardEditModalId(null)
                        }}
                    >
                        Remove Task
                    </button>
                    <button
                        // FOR FUTURE onClick={() => tasks.find((x) => x._id == cardEditModalId).isArchived ? activeTask(cardEditModalId) : archiveTask(cardEditModalId)}
                        onClick={() => archiveTask(cardEditModalId)}
                        onBlur={() => upsertCard(tasks.find((x) => x._id == cardEditModalId))}
                        className='px-2 py-1 mt-4 text-sm font-normal text-white bg-blue-500 rounded'
                        // FOR FUTURE >{tasks.find((x) => x._id == cardEditModalId).isArchived ? 'Unarchive Task' : 'Archive Task'}</button>
                    >
                        Archive Task
                    </button>
                </div>
            </FormModal>

            <FormModal
                open={showGroupingModal}
                setOpen={() => {
                    setShowGroupingModal(false)
                }}
            >
                {editingInputGroupingName === null ? (
                    <TextField
                        label='New grouping:'
                        onChange={() => {}}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.target.value !== '') {
                                if (!groupings.map((grouping) => grouping.name).includes(e.target.value)) {
                                    setGroupings([...groupings, { name: e.target.value }])
                                    setShowGroupingModal(false)
                                } else {
                                    toast('Grouping already exists!')
                                }
                            }
                        }}
                        onBlur={(e) => {
                            if (e.target.value !== '') {
                                if (!groupings.map((grouping) => grouping.name).includes(e.target.value)) {
                                    setGroupings([...groupings, { name: e.target.value }])
                                    setShowGroupingModal(false)
                                } else {
                                    toast('Grouping already exists!')
                                }
                            }
                        }}
                    />
                ) : (
                    <TextField
                        label='Edit grouping:'
                        value={editingInputGroupingName}
                        onChange={(val) => {
                            setEditingInputGroupingName(val)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.target.value !== '') {
                                if (groupings.map((grouping) => grouping.name).includes(editingInputGroupingName)) {
                                    if (editingInputGroupingName === groupingEditModalName) {
                                        setShowGroupingModal(false)
                                    } else {
                                        toast('Grouping already exists!')
                                    }
                                } else {
                                    api(`${process.env.REACT_APP_API_URL}/admin/tasks/update`, { query: { grouping: groupingEditModalName }, update: { grouping: editingInputGroupingName } }).then(() => {
                                        const updatedGroupings = groupings.map((grouping) => {
                                            if (grouping.name === groupingEditModalName) {
                                                return { ...grouping, name: editingInputGroupingName }
                                            } else {
                                                return grouping
                                            }
                                        })

                                        const updatedTasks = tasks.map((task) => {
                                            if (task.grouping === groupingEditModalName) {
                                                return { ...task, grouping: editingInputGroupingName }
                                            } else {
                                                return task
                                            }
                                        })

                                        setTasks(updatedTasks)
                                        setGroupings(updatedGroupings)
                                        setShowGroupingModal(false)
                                    })
                                }
                            }
                        }}
                        onBlur={(e) => {
                            if (e.target.value !== '') {
                                if (!groupings.map((grouping) => grouping.name).includes(e.target.value)) {
                                    api(`${process.env.REACT_APP_API_URL}/admin/tasks/update`, { query: { grouping: groupingEditModalName }, update: { grouping: editingInputGroupingName } }).then(() => {
                                        const updatedGroupings = groupings.map((grouping) => {
                                            if (grouping.name === groupingEditModalName) {
                                                return { ...grouping, name: editingInputGroupingName }
                                            } else {
                                                return grouping
                                            }
                                        })

                                        const updatedTasks = tasks.map((task) => {
                                            if (task.grouping === groupingEditModalName) {
                                                return { ...task, grouping: editingInputGroupingName }
                                            } else {
                                                return task
                                            }
                                        })

                                        setTasks(updatedTasks)
                                        setGroupings(updatedGroupings)
                                        setShowGroupingModal(false)
                                    })
                                } else {
                                    toast('Grouping already exists!')
                                }
                            }
                        }}
                    />
                )}
            </FormModal>

            <button className='px-2 py-1 mt-4 ml-5 text-sm font-normal text-white bg-green-600 rounded' onClick={() => setShowArchived(!showArchived)}>
                {showArchived ? 'Hide Archived' : 'Show Archived'}
            </button>

            <div className={`flex items-center px-2 ${groupingFilters.length > 0 ? 'py-1' : 'py-2'} mt-2 ml-5 overflow-x-scroll rounded bg-slate-200 no-scrollbar`}>
                <div className='pr-2'>Groupings: </div>
                {groupings.length > 0 &&
                    groupings.map((grouping) => (
                        <div className={`px-2 mx-1 rounded-md flex items-center cursor-pointer ${groupingFilters.includes(grouping.name) ? 'border-4 border-gray-50' : ''}  ${grouping.colour}`}>
                            <div
                                onClick={() => {
                                    if (!groupingFilters.includes(grouping.name)) {
                                        setGroupingFilters([...groupingFilters, grouping.name])
                                    } else {
                                        setGroupingFilters(groupingFilters.filter((x) => x !== grouping.name))
                                    }
                                }}
                            >
                                {grouping.name}
                            </div>
                            <div className='pt-1 pb-2 pl-1 '>
                                <XMarkIcon
                                    className='h-4'
                                    onClick={() => {
                                        api(`${process.env.REACT_APP_API_URL}/admin/tasks/update`, { query: { grouping: grouping.name }, update: { grouping: '' } }).then(() => {
                                            setGroupings(groupings.filter((x) => x.name !== grouping.name))
                                            if (groupingFilters.includes(grouping.name)) setGroupingFilters(groupingFilters.filter((x) => x !== grouping.name))
                                        })
                                    }}
                                />
                                <PencilIcon
                                    className='h-3'
                                    onClick={() => {
                                        setGroupingEditModalName(grouping.name)
                                        setEditingInputGroupingName(grouping.name)
                                        setShowGroupingModal(true)
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                <div
                    className='px-2 mx-1 border border-black border-dashed rounded-md'
                    onClick={() => {
                        setShowGroupingModal(true)
                    }}
                >
                    Add grouping
                </div>
            </div>

            <div className='flex h-[90vh] p-5 space-x-4 overflow-x-scroll'>
                <DragDropContext onDragEnd={(e, v) => onDragEnd(e, v)}>
                    {lanes.slice(0, showArchived ? 6 : 5).map((lane, laneId) => (
                        <div className='min-w-[276px] py-5 pl-3 overflow-scroll rounded shadow bg-slate-200 w-[276px]'>
                            <div className='font-semibold tracking-tighter select-none text-neutral-800'>{lane}</div>
                            <div
                                className='font-medium cursor-pointer text-slate-600 hover:underline'
                                onClick={() => {
                                    upsertCard({ title: 'New Card', lane: laneId, index: 0 })
                                }}
                            >
                                Add a task...
                            </div>

                            <Droppable droppableId={`lane_${laneId}`}>
                                {(provided, snapshot) => (
                                    <div className='min-h-[20px]' {...provided.droppableProps} ref={provided.innerRef}>
                                        {tasks
                                            ?.filter((x) => x.lane == laneId || (!x.lane && laneId == 0))
                                            .sort((a, b) => a.index - b.index)
                                            .map(
                                                (x, i) =>
                                                    (groupingFilters.length === 0 || groupingFilters.includes(x.grouping)) && (
                                                        <Draggable draggableId={x._id} index={i} key={x._id}>
                                                            {(provided, snapshot) => (
                                                                <div className='py-2' onClick={() => openEditModal(x._id)} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className={`flex items-baseline justify-between p-2 text-sm font-medium select-none rounded-t-md  text-neutral-700 ${groupings?.find((grouping) => grouping.name === x.grouping)?.colour ? groupings?.find((grouping) => grouping.name === x.grouping)?.colour : 'bg-slate-300'}`}>
                                                                        <div className='truncate'>{x.title}</div>
                                                                        {x.assignedTo?.name && <div className='p-1 px-2 text-xs font-semibold text-white capitalize bg-green-500 rounded-full'>{x.assignedTo?.name[0]}</div>}
                                                                    </div>
                                                                    <div className={`bg-neutral-50 tracking-wide rounded-b-md p-2 text-xs text-neutral-700 select-none`}>{x.text}</div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                            )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>

                            {tasks?.filter((x) => x.lane == laneId || (!x.lane && laneId == 0)).length > 1 && <div
                                className='font-medium cursor-pointer text-slate-600 hover:underline'
                                onClick={() => {
                                    upsertCard({ title: 'New Card', lane: laneId, index: 0 })
                                }}
                            >
                                Add a task...
                            </div>}
                        </div>
                    ))}
                </DragDropContext>
            </div>
        </>
    )
}
// bleh
