export default function TextField({ label, value, onChange, onKeyDown, className, inputClass, labelClass, placeholder, onBlur, type, required, disabled = null }) {
    return (
        <div className={className}>
            {label && (
                <label className={`block text-sm mb-1 font-medium leading-6 text-neutral-600 ${labelClass}`}>
                    {label} {required && <span className='text-red-500'>*</span>}
                </label>
            )}
            <input required={required} type={type ? type : 'text'} onFocus={(e) => e.target.select()} onBlur={onBlur} onKeyDown={onKeyDown} disabled={disabled} placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} className={`block w-full rounded border-0 px-3 p-2 text-neutral-700 shadow-sm  ring-gray-200 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-1 focus:ring-gray-200 sm:text-sm sm:leading-6 ${inputClass}`} />
        </div>
    )
}
