import { ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline'

// Column/List heading with an animated icon, click to update a sorting algorithm (compatible function included below)
// where sortAlg = { accessor: string | (x) => bool, isAscending: bool }
export default function SortToggle({ text, accessor, state, onChange, className, defaultAscending = true }) {
    return (
        <div className={`flex justify-center w-full h-full items-center text-center cursor-pointer select-none ${className}`} onClick={() => onChange({ accessor: accessor, isAscending: isCurrentAccessor(accessor, state.accessor) ? !state.isAscending : defaultAscending })}>
            {text}{' '}
            <div className='flex items-center justify-center w-6 h-6 ml-1 min-w-6 min-h-6'>
                <ArrowUpIcon className={`${isCurrentAccessor(accessor, state.accessor) ? 'h-6 w-6 opacity-100' : 'h-0 w-0 opacity-0'} ${state.isAscending ? 'rotate-0' : 'rotate-180'} transition-all`} />
                <ArrowsUpDownIcon className={`${isCurrentAccessor(accessor, state.accessor) ? 'h-0 w-0 opacity-0' : 'h-5 w-5 opacity-100'} opacity-30 transition-all`} />
            </div>
        </div>
    )
}

// so that accessor can be a function
function isCurrentAccessor(source, target) {
    if (typeof source !== typeof target) {
        return false
    }

    if (typeof source === 'function') {
        return source.toString() === target.toString()
    }

    return source === target
}

// Array.sort() callback with extra props compatible with accessor functions
export function sortFn(a, b, { accessor, isAscending }) {
    if (typeof accessor === 'function') {
        a = accessor(a)
        b = accessor(b)
    } else {
        a = a[accessor]
        b = b[accessor]
    }

    if (a === undefined) return b === undefined ? 0 : 1
    if (b === undefined) return -1

    return (a > b ? 1 : -1) * (isAscending ? -1 : 1)
}
