import { useEffect, useRef, useState } from 'react'

export default function Card({ children, title, className }) {
    return (
        <div className={`px-2 py-2 my-2 border-y border-gray-300 shadow sm:rounded-lg sm:border-x sm:px-4 sm:py-4 sm:my-4 overflow-hidden ${className}`}>
            {title && (
                <div className='flex justify-between pb-1 mb-1 -mx-2 border-b border-gray-300 sm:-mx-4 sm:pb-2 sm:mb-2 lg:pb-3 lg:mb-3'>
                    <h2 className='mx-4 text-xl font-semibold text-gray-700'>{title}</h2>
                </div>
            )}
            {children}
        </div>
    )
}

export function FadeInCard({ children, title, className }) {
    const { containerRef, isVisible } = useIntersectionObserver({ threshold: 0.2 })
    useEffect(() => {
        if (isVisible) {
            containerRef.current.classList.remove('invisible')
            containerRef.current.classList.add('animate-fade-up')
        }
    }, [isVisible])

    return (
        <div ref={containerRef} className={`invisible px-2 py-2 my-2 border-y border-gray-300 shadow sm:rounded-lg sm:border-x sm:px-4 sm:py-4 sm:my-4 overflow-hidden ${className}`}>
            {title && (
                <div className='flex justify-between pb-1 mb-1 -mx-2 border-b border-gray-300 sm:-mx-4 sm:pb-2 sm:mb-2 lg:pb-3 lg:mb-3'>
                    <h2 className='mx-4 text-xl font-semibold text-gray-700'>{title}</h2>
                </div>
            )}
            {children}
        </div>
    )
}

function useIntersectionObserver(options) {
    const containerRef = useRef()
    const [isVisible, setVisible] = useState(false)

    function callback(items) {
        const [item] = items
        setVisible(item.isIntersecting)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callback, options)
        if (containerRef.current) observer.observe(containerRef.current)

        return () => {
            if (containerRef.current) observer.unobserve(containerRef.current)
        }
    }, [containerRef, options])

    return { containerRef, isVisible }
}
