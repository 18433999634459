import { useEffect, useState } from 'react'

import { api } from '../api.service'
import logo from '../logo.png'
import ImageViewer from './image-viewer'
import Loading from './loading'
import PageHeader from './page-header'

export default function ScopeDocumentWrap() {
    const [data, setData] = useState(null)

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/project`).then((x) => setData(x))
    }, [])

    if (!data) return <Loading></Loading>

    return (
        <>
            <PageHeader headline={'Scope'}></PageHeader>
            <ScopeDocumentViewer data={data}></ScopeDocumentViewer>
        </>
    )
}

export function ScopeDocumentViewer({ data }) {
    return (
        <div className='flex flex-col max-w-6xl p-10 mx-auto font-medium bg-white shadow text-neutral-800'>
            <div className='grid grid-cols-4 gap-2'>
                <img src={logo} alt='Verticode'></img>
                <div className='col-span-2 mt-6 text-3xl font-bold text-center'>{data.name} - MVP Scope</div>
                <ImageViewer image={data.logo} className='rounded-lg shadow'></ImageViewer>
            </div>

            <div className='mt-10 mb-5 text-xl text-center whitespace-pre-wrap text-neutral-600'>{data.description}</div>

            <div className='mt-5 text-lg text-center whitespace-pre-wrap text-neutral-700'>{data.scopeOverview}</div>

            <div className='mt-16 text-2xl font-bold text-center'>Colours</div>
            <div className='my-8 text-lg text-center text-neutral-700'>These are the main colours that will be used in the development of the {data.name} application</div>

            <div className='flex flex-wrap justify-around'>
                {data.colours?.map((x) => (
                    <div className='flex flex-col mx-2 text-center'>
                        <div className='text-xl font-semibold'>{x.name}</div>
                        <div className='w-16 h-16 mx-auto shadow' style={{ background: x.hex }}></div>
                        <div className='my-2 text-neutral-600'>{x.hex}</div>
                    </div>
                ))}
            </div>

            <div className='mt-16 text-2xl font-bold'>Pages</div>
            {data.pages?.map((x) => (
                <div className='my-4'>
                    <div className='text-lg font-semibold'>{x.name}</div>
                    <div className='my-2 text-neutral-600'>{x.description}</div>
                </div>
            ))}

            <div className='mt-16 text-2xl font-bold'>Features</div>
            {data.features?.map((x) => (
                <div className='my-4'>
                    <div className='text-lg font-semibold'>{x.name}</div>
                    <div className='my-2 text-neutral-600'>{x.description}</div>
                </div>
            ))}

            {data.customSections?.map((x) => (
                <div className='mt-16'>
                    <div className='text-2xl font-bold'>{x.title}</div>
                    <div className='my-2 whitespace-pre-wrap text-neutral-600'>{x.text}</div>
                </div>
            ))}
            <div className='mt-16'>
                <div className='text-2xl font-bold'>Tech Stack</div>
                <div className='my-2 text-neutral-600'>
                    Verticode will develop this Web Application using the MERN javascript techstack. Where required other technologies and frameworks can be used.
                    <ul>
                        <li>
                            <b>Front End</b>: react.js
                        </li>
                        <li>
                            <b>Back End</b>: node.js & express.js
                        </li>
                        <li>
                            <b>Data</b>: mongoDB
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
