import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from 'react'

import Loading from '../../components/loading'

import { PROJECT_COUNTRY } from '../../admin/admin-project-countries'
import { PAYMENT_STATUS, PROJECT_STAGE, WORK_STATUS } from '../../admin/admin-project-statuses'
import { api } from '../../api.service'

export default function SelectField({ label, value, onChange, className, inputClass, labelClass, options, optionListName, placeholder }) {
    const [selectOptions, setSelectOptions] = useState(options)

    useEffect(() => {
        if (optionListName === 'users') {
            api(`${process.env.REACT_APP_API_URL}/admin/user`).then((x) =>
                setSelectOptions(
                    [{text: '--', value: null}, ...x.map((x) => {
                        return { text: x.email, value: x._id }
                    })]
                )
            )
        }

        if (optionListName === 'code-templates') {
            api(`${process.env.REACT_APP_API_URL}/admin/code-template`).then((x) =>
                setSelectOptions(
                    x.map((x) => {
                        return { text: x.title, value: x._id }
                    })
                )
            )
        }

        if (optionListName === 'projectStage') {
            setSelectOptions(PROJECT_STAGE)
        }

        if (optionListName === 'workStatus') {
            setSelectOptions(WORK_STATUS)
        }

        if (optionListName === 'paymentStatus') {
            setSelectOptions(PAYMENT_STATUS)
        }
        if (optionListName === 'country') {
            setSelectOptions(PROJECT_COUNTRY)
        }
    }, [])
    const [query, setQuery] = useState('')

    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))

    if (!selectOptions) return <Loading></Loading>
    return (
        <div className={className}>
            {label && <label className={`block text-sm my-1 font-medium leading-6 text-gray-900 ${labelClass}`}>{label}</label>}
            <div className='relative mt-2 rounded-md shadow-sm'>
                <Combobox
                    value={selectOptions.find((x) => x.value == value)}
                    onChange={(e) => {
                        onChange(e)
                    }}
                >
                    <div className='relative '>
                        <div className='relative w-full overflow-hidden text-left bg-white border-0 rounded-md shadow-sm cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 sm:text-sm'>
                            <Combobox.Input placeholder={placeholder} className='w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border-none placeholder:text-gray-400 focus:ring-0' onChange={(e) => setQuery(e.target.value)} displayValue={(x) => x?.text} />
                            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                                <ChevronUpDownIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                            </Combobox.Button>
                        </div>
                        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0' afterLeave={() => setQuery('')}>
                            <Combobox.Options className='absolute z-40 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                {filtered.map((x) => (
                                    <Combobox.Option key={x.value} value={x?.value ?? null} className={({ active }) => `relative cursor-default select-none py-2 px-3  ${x.value === value ? 'bg-green-600 text-white' : 'text-gray-900'} ${x.value !== value && active ? 'bg-green-400 text-white' : 'text-gray-900'}`}>
                                        {x.text}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        </Transition>
                    </div>
                </Combobox>
            </div>
        </div>
    )
}
