import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { ArrowDownIcon, ChartBarIcon, DocumentIcon, GlobeAltIcon, HomeIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

import logo from '../logo-dark.png'

const navigation = [
    { name: 'Home', href: '/app', icon: HomeIcon, current: true },
    { name: 'Scope', href: '/app/scope', icon: CheckIcon, current: true },
    { name: 'Contract', href: '/app/contract', icon: DocumentIcon, current: true },
    { name: 'Connect Domain', href: '/app/domain', icon: GlobeAltIcon, current: true },
    { name: 'Analytics', href: '/app/analytics', icon: ChartBarIcon, current: true },
]
const secondaryNavigation = [{ name: 'Logout', href: '/logout', icon: ArrowDownIcon }]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AppLayout() {
    const location = useLocation()
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <>
            <div className='min-h-full'>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as='div' className='relative z-40 lg:hidden' onClose={setSidebarOpen}>
                        <Transition.Child as={Fragment} enter='transition-opacity ease-linear duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='transition-opacity ease-linear duration-300' leaveFrom='opacity-100' leaveTo='opacity-0'>
                            <div className='fixed inset-0 bg-green-600 bg-opacity-75' />
                        </Transition.Child>

                        <div className='fixed inset-0 z-40 flex'>
                            <Transition.Child as={Fragment} enter='transition ease-in-out duration-300 transform' enterFrom='-translate-x-full' enterTo='translate-x-0' leave='transition ease-in-out duration-300 transform' leaveFrom='translate-x-0' leaveTo='-translate-x-full'>
                                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-green-700 pt-5 pb-4'>
                                    <Transition.Child as={Fragment} enter='ease-in-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in-out duration-300' leaveFrom='opacity-100' leaveTo='opacity-0'>
                                        <div className='absolute top-0 right-0 -mr-12 pt-2'>
                                            <button type='button' className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white' onClick={() => setSidebarOpen(false)}>
                                                <span className='sr-only'>Close sidebar</span>
                                                <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className='flex flex-shrink-0 items-center px-4'>
                                        <img className='w-full h-12 object-cover' src={logo} />
                                    </div>
                                    <nav className='mt-5 h-full flex-shrink-0 divide-y divide-green-800 overflow-y-auto' aria-label='Sidebar'>
                                        <div className='space-y-1 px-2'>
                                            {navigation.map((item) => (
                                                <>
                                                    {item.category && <div>{item.category}</div>}
                                                    <Link key={item.name} to={item.href} className={classNames(location.pathname.endsWith(item.href) ? 'bg-green-800 text-white' : 'text-green-100 hover:text-white hover:bg-green-600', 'group flex items-center px-2 py-2 text-base font-medium rounded-md')} aria-current={item.current ? 'page' : undefined}>
                                                        <item.icon className='mr-4 h-6 w-6 flex-shrink-0 text-green-200' aria-hidden='true' />
                                                        {item.name}
                                                    </Link>
                                                </>
                                            ))}
                                        </div>
                                        <div className='mt-6 pt-6'>
                                            <div className='space-y-1 px-2'>
                                                {secondaryNavigation.map((item) => (
                                                    <a key={item.name} href={item.href} className='group flex items-center rounded-md px-2 py-2 text-base font-medium text-green-100 hover:bg-green-600 hover:text-white'>
                                                        <item.icon className='mr-4 h-6 w-6 text-green-200' aria-hidden='true' />
                                                        {item.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </nav>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div className='w-14 flex-shrink-0' aria-hidden='true'>
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col'>
                    <div className='flex flex-grow flex-col overflow-y-auto bg-green-700 pt-5 pb-4'>
                        <div className='flex flex-shrink-0 items-center px-4'>
                            <img className='h-24 mx-auto object-cover' src={logo} />
                        </div>

                        <nav className='mt-5 flex flex-1 flex-col divide-y divide-green-800 overflow-y-auto' aria-label='Sidebar'>
                            <div className='space-y-1 px-2'>
                                {navigation.map((item) => (
                                    <>
                                        {item.category && <div className='text-xs text-green-500 font-semibold ml-3 pt-5'>{item.category}</div>}
                                        <Link key={item.name} to={item.href} className={classNames(location.pathname.endsWith(item.href) ? 'bg-green-800 text-white' : 'text-green-100 hover:text-white hover:bg-green-600', 'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md')} aria-current={item.current ? 'page' : undefined}>
                                            <item.icon className='mr-4 h-6 w-6 flex-shrink-0 text-green-200' aria-hidden='true' />
                                            {item.name}
                                        </Link>
                                    </>
                                ))}
                            </div>
                            <div className='mt-6 pt-6'>
                                <div className='space-y-1 px-2'>
                                    {secondaryNavigation.map((item) => (
                                        <a key={item.name} href={item.href} className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-green-100 hover:bg-green-600 hover:text-white'>
                                            <item.icon className='mr-4 h-6 w-6 text-green-200' aria-hidden='true' />
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                <div className='flex flex-1 flex-col lg:pl-64 bg-neutral-50 min-h-screen'>
                    <main className='flex-1'>
                        <Outlet></Outlet>
                    </main>
                </div>
            </div>
        </>
    )
}
