import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'

export const PROJECT_STAGE = [
    { value: -1, text: 'Declined', colour: 'gray-400', hex: '#9ca3af' },
    { value: 0, text: 'Prospect', colour: 'sky-200', hex: '#bae6fd' },
    { value: 1, text: 'Scoping', colour: 'sky-500', hex: '#0ea5e9' },
    { value: 2, text: 'Contract', colour: 'amber-500', hex: '#f59e0b' },
    { value: 3, text: 'Development', colour: 'purple-500', hex: '#a855f7' },
    { value: 4, text: 'Feedback', colour: 'green-300', hex: '#86efac' },
    { value: 5, text: 'Delivered', colour: 'green-600', hex: '#16a34a' },
]

export const WORK_STATUS = [
    { value: -1, text: 'Outstanding', colour: 'red-400' },
    { value: 0, text: 'Waiting for Client', colour: 'amber-500' },
    { value: 1, text: 'Development', colour: 'purple-500' },
    { value: 2, text: 'Testing', colour: 'yellow-300' },
    { value: 3, text: 'Live', colour: 'green-600' },
]

export const PAYMENT_STATUS = [
    { value: -1, text: 'Overdue', colour: 'red-500' },
    { value: 0, text: 'Pre-payment', colour: 'sky-500' },
    { value: 1, text: 'Invoice Sent', colour: 'amber-500' },
    { value: 2, text: 'Paid', colour: 'green-600' },
]

export function StatusReminder() {
    const [open, setOpen] = useState(false)

    return (
        <div className='relative'>
            <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)} className='w-[24px] h-[24px] cursor-pointer'>
                <InformationCircleIcon />
            </div>
            {open && (
                <div className={`absolute top-[26px] left-[26px] flex flex-col items-center justify-center bg-white z-50 p-2 rounded-md ring-1 ring-gray-900/10`}>
                    <h1 className='mb-2 text-base font-semibold'>Project Stages</h1>
                    {PROJECT_STAGE.map((stage) => (
                        <span key={stage.value} className={`bg-${stage.colour} p-2 w-full h-min text-center text-white font-semibold`}>
                            {stage.text}
                        </span>
                    ))}
                </div>
            )}
        </div>
    )
}
