import { CodeBracketIcon, DocumentIcon, GlobeAltIcon, MagnifyingGlassCircleIcon, MagnifyingGlassIcon, WrenchIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../components/button'
import Confirm from '../components/confirm'
import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

import { api, api_delete } from '../api.service'

export default function ProjectDashboard() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/project`).then((x) => setData(x))
    }, [])

    if (!data) return <Loading></Loading>
    function openInNewTab(url) {
        var win = window.open(url, '_blank')
        win.focus()
    }
    return (
        <>
            <PageHeader headline={data.name + ' - MVP'} actions={<Button text='View MVP' onClick={() => openInNewTab(data.url)}></Button>}></PageHeader>
            <div className='flex'>
                <div className='w-full max-w-6xl pb-20 mx-auto my-10'>
                    <div className='text-2xl font-semibold text-center text-neutral-800'>Welcome to the Verticode Client Portal</div>
                    <div className='mt-5 mb-10 text-lg font-semibold text-center text-neutral-600'>Keep track of the status of your project, view important documents, set up a custom domain, and integrate analytics into your MVP</div>

                    <div className='flex bg-white rounded-md shadow-md'>
                        <div className='flex py-10 pl-10'>
                            <ImageViewer className='w-32 h-32 m-auto rounded shadow' image={data.logo}></ImageViewer>
                        </div>
                        <div className='flex-1 p-10'>
                            <div className='my-2 text-2xl font-semibold text-neutral-800'>{data.name}</div>
                            <div className='text-neutral-700 text-md'>{data.description}</div>
                        </div>
                    </div>

                    <div className='mt-10 bg-white rounded-md shadow-md'>
                        <div className='flex-1 px-10 pt-10'>
                            <div className='my-2 text-3xl font-semibold text-center text-neutral-800 '>Project Roadmap</div>
                        </div>

                        <div className='flex justify-between p-10 pl-14'>
                            <div className='flex flex-1'>
                                <div className='flex text-center bg-green-500 rounded-full shadow-lg shrink-0 w-44 h-44'>
                                    <div className='m-auto'>
                                        <MagnifyingGlassIcon className='w-16 h-16 m-auto text-neutral-100'></MagnifyingGlassIcon>
                                        <div className='text-xl font-semibold text-white'>Scoping</div>
                                    </div>
                                </div>
                                <div className={`flex-1 h-2 my-auto ${data.status > 0 ? 'bg-green-500' : 'bg-neutral-200'}`}></div>
                            </div>

                            <div className='flex flex-1'>
                                <div className={`shrink-0 w-44 h-44  shadow-lg rounded-full flex text-center ${data.status > 0 ? 'bg-green-500' : 'bg-neutral-200'}`}>
                                    <div className='m-auto'>
                                        <DocumentIcon className='w-16 h-16 m-auto text-neutral-100'></DocumentIcon>
                                        <div className='text-xl font-semibold text-white'>Contract</div>
                                    </div>
                                </div>
                                <div className={`flex-1 h-2 my-auto ${data.status > 1 ? 'bg-green-500' : 'bg-neutral-200'}`}></div>
                            </div>

                            <div className='flex flex-1'>
                                <div className={`shrink-0 w-44 h-44  shadow-lg rounded-full flex text-center ${data.status > 1 ? 'bg-green-500' : 'bg-neutral-200'}`}>
                                    <div className='m-auto'>
                                        <CodeBracketIcon className='w-16 h-16 m-auto text-neutral-100'></CodeBracketIcon>
                                        <div className='text-xl font-semibold text-white'>Development</div>
                                    </div>
                                </div>
                                <div className={`flex-1 h-2 my-auto ${data.status > 2 ? 'bg-green-500' : 'bg-neutral-200'}`}></div>
                            </div>

                            <div className='flex flex-1'>
                                <div className={`shrink-0 w-44 h-44  shadow-lg rounded-full flex text-center ${data.status > 2 ? 'bg-green-500' : 'bg-neutral-200'}`}>
                                    <div className='m-auto'>
                                        <WrenchIcon className='w-16 h-16 m-auto text-neutral-100'></WrenchIcon>
                                        <div className='text-xl font-semibold text-white'>Testing</div>
                                    </div>
                                </div>
                                <div className={`flex-1 h-2 my-auto ${data.status > 3 ? 'bg-green-500' : 'bg-neutral-200'}`}></div>
                            </div>

                            <div className='flex flex-1'>
                                <div className={`shrink-0 w-44 h-44  shadow-lg rounded-full flex text-center ${data.status > 3 ? 'bg-green-500' : 'bg-neutral-200'}`}>
                                    <div className='m-auto'>
                                        <GlobeAltIcon className='w-16 h-16 m-auto text-neutral-100'></GlobeAltIcon>
                                        <div className='text-xl font-semibold text-white'>MVP Live</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pb-10 text-center'>
                            {data.status == 0 && (
                                <div className='text-lg text-neutral-800'>
                                    We are currently in the scoping stage of your project. Click{' '}
                                    <a className='font-semibold text-green-500 cursor-pointer' onClick={() => navigate('/app/scope')}>
                                        here
                                    </a>{' '}
                                    to view the current version of you scoping document.
                                </div>
                            )}
                            {data.status == 1 && (
                                <div className='text-neutral-700 text-md'>
                                    We have prepared a contract for you to sign. Once you have signed the contract we can get started with development right away. Click{' '}
                                    <a className='font-semibold text-green-500 cursor-pointer' onClick={() => navigate('/app/contract')}>
                                        here
                                    </a>{' '}
                                    to view the development contract.
                                </div>
                            )}
                            {data.status == 2 && <div className='text-neutral-700 text-md'>We have commenced development of the {data.name} MVP. We will be in touch with updates as development continues</div>}
                            {data.status == 3 && <div className='text-neutral-700 text-md'>Your MVP is now on our test server. We're testing internally now and you can begin your own testing.</div>}
                            {data.status == 4 && <div className='text-neutral-700 text-md'>Your MVP is now live!</div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
