import { useEffect, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket'

import Card from '../components/card'
import ContentWidthWrapper from '../components/content-width-wrapper'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'

import SelectField from '../forms/fields/select-field'
import TimeTrackerForm from '../forms/time-tracker-form'

import { api } from '../api.service'

export default function AdminDevTime(props) {
    const [data, setData] = useState(null)
    const [user, setUser] = useState(null)
    const [projects, setProjects] = useState(null)
    const [selected, setSelected] = useState(null)

    const { sendMessage, lastMessage, readyState } = useWebSocket(process.env.REACT_APP_WS_URL)

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (lastMessage?.data?.includes('time')) {
            loadData()
        }
    }, [lastMessage])

    useEffect(() => {
        console.log(user)
    }, [user])

    function loadData() {
        api(`${process.env.REACT_APP_API_URL}/admin/time`).then((x) => setData(x))
        api(`${process.env.REACT_APP_API_URL}/admin/project`).then((x) => setProjects(x))
        api(`${process.env.REACT_APP_API_URL}/auth/whoami`, { data: { token: localStorage.getItem('token') ?? '' } }).then((x) => setUser(x))
    }

    function createItem(formValues) {
        api(`${process.env.REACT_APP_API_URL}/admin/time`, { data: { project: selected, assignedTo: user?.id, ...formValues } })
    }

    if (!data || !projects) return <Loading></Loading>

    return (
        <>
            <PageHeader headline='Development Time'></PageHeader>
            <ContentWidthWrapper>
                <Card className='bg-white'>
                    <h2 className='text-xl font-bold'>Select a Project</h2>
                    <SelectField
                        value={selected}
                        onChange={(val) => setSelected(val)}
                        options={projects.map((d) => {
                            return { text: d?.projectName ?? d?.name ?? '', value: d._id }
                        })}
                        className='my-2'
                    ></SelectField>
                    <hr className='my-4' />
                    <TimeTrackerForm userName={user?.name ?? 'Unknown User'} onSubmit={(values) => createItem(values)} />
                </Card>
            </ContentWidthWrapper>
        </>
    )
}
