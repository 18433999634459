import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Disclosure } from "@headlessui/react"
import { MinusSmallIcon, PlusSmallIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline"

import Button from '../components/button'
import Confirm from '../components/confirm'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'
import TextField from '../forms/fields/text-field'

import { SandpackCodeEditor, SandpackLayout, SandpackPreview, SandpackProvider } from "@codesandbox/sandpack-react"

import { api, api_delete } from '../api.service'
import Tabs from '../components/tabs'

export default function AdminComponents() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [displayData, setDisplayData] = useState(null)
    const [deleteId, setDeleteId] = useState()
    const [tab, setTab] = useState(0)
    const [categories, setCategories] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [categoryFilter, setCategoryFilter] = useState('')

    const deleteConfirm = () => {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/component/${deleteId}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/admin/component`)
                .then((x) => setData(x))
                .then((x) => setDeleteId(null))
        })
    }

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/component`)
            .then((x) => {
                setData(x)
                setDisplayData(x)
                setCategories(new Set(x.map(y => y.category)))
            })
    }, [])

    useEffect(() => {
        if (categoryFilter === '') {
            setDisplayData(data)
        } else {
            setDisplayData(data.filter(item => item.category === categoryFilter))
        }
    }, [categoryFilter])

    const handleCopyCode = (e, x) => {
        e.stopPropagation()
        navigator.clipboard.writeText(x.code)
    }

    const handleSearch = () => {
        let searchTerm = new RegExp(searchValue, 'i');
        const keyMatches = ['category', 'tags', 'name']
        const sortedData = data.filter(item => {
            for (const key of keyMatches) {
                if (searchTerm.test(item[key])) {
                    return true
                }
            } return false
        })
        setDisplayData(sortedData)
    }

    if (!data) return <Loading></Loading>

    return (
        <>
            <Confirm open={!!deleteId} onCancel={() => setDeleteId(false)} onConfirm={() => deleteConfirm()}></Confirm>

            <PageHeader headline={'Components'} actions={<Button text='Add Component' onClick={() => navigate('/admin/manage-component/new')}></Button>}></PageHeader>
            <div className='flex'>
                <div className='w-full max-w-6xl pb-20 mx-auto my-10'>

                    <Tabs activeIdx={tab} onChange={setTab} tabs={[{ name: 'List' }, { name: 'Table' }]}></Tabs>

                    
                    {tab == 1 && <div className='px-4'>
                        <Table
                            data={data.map((x) => {
                                return {
                                    ...x,
                                    edit: (
                                        <span className='underline cursor-pointer' onClick={() => navigate(`/admin/manage-component/${x._id}`)}>
                                            Edit
                                        </span>
                                    ),
                                    deleteCell: (
                                        <span className='underline cursor-pointer' onClick={() => setDeleteId(x._id)}>
                                            Delete
                                        </span>
                                    ),
                                }
                            })}
                            columns={[
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                },
                                {
                                    Header: 'Edit',
                                    accessor: 'edit',
                                },
                                {
                                    Header: 'Delete',
                                    accessor: 'deleteCell',
                                },
                            ]}
                        ></Table>
                    </div>}


                    {tab == 0 && 
                    <div>
                        {categories && 
                        <div>
                            <h2 className='pl-4 mt-4 font-semibold'>Filter by category</h2>
                            <CategoryDisplay categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter} categories={categories} />
                        </div>
                        }

                        <div className='flex mx-4 my-6'>
                            <TextField className='flex-1 mr-6' inputClass='!bg-white focus:border-2 focus:border-green-500' placeholder='Search for component...' value={searchValue} onChange={(val) => setSearchValue(val)} />
                            <button className='self-center px-4 py-2 text-sm text-white bg-green-600 rounded shadow' onClick={handleSearch}>Search</button>
                        </div>
                        <div className='px-6 pb-4 mx-4 bg-white rounded'>
                            {displayData.map((x) => <div >
                                <Disclosure as="div" key={x.name} className="p-2 border-b border-slate-200 hover:bg-slate-100">
                                    {({ open }) => (
                                        <>
                                            <dt>
                                                <Disclosure.Button className="grid w-full grid-cols-3 text-left text-neutral-700">
                                                    <span className="text-base font-semibold leading-7">{x.name}</span>
                                                    <DocumentDuplicateIcon onClick={(e) => handleCopyCode(e, x)} className='items-center w-6 h-6 justify-self-center text-slate-600 hover:text-black' />
                                                    <span className="flex items-center ml-6 h-7 justify-self-end">
                                                        {open ? (
                                                            <MinusSmallIcon className="w-6 h-6" aria-hidden="true" />
                                                        ) : (
                                                            <PlusSmallIcon className="w-6 h-6" aria-hidden="true" />
                                                        )}
                                                    </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="pr-12 mt-2">
                                                <span className='text-base font-normal leading-8'>{x?.description}</span>
                                                <SandpackProvider
                                                    template="react-ts"
                                                    theme="dark"
                                                    options={{
                                                        externalResources: ["https://cdn.tailwindcss.com"]
                                                    }}
                                                    files={x.sandbox?.files} >
                                                    <Sandbox></Sandbox>
                                                </SandpackProvider>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            </div>)
                            }
                        </div>
                    </div>
                    }
                </div>
            </div>
        </>
    )
}

function CategoryDisplay({ setCategoryFilter, categoryFilter, categories }) {
    return(
        <div className='grid grid-cols-4 gap-6 mx-4 my-2'>
            {Array.from(categories).map(category => {
                return (
                    <div onClick={() =>setCategoryFilter(categoryFilter === category ? '' : category)} className={`p-2 text-sm font-semibold bg-white rounded shadow cursor-pointer ${categoryFilter === category ? 'bg-slate-700 text-white' : 'hover:bg-slate-100'}`}>
                        {category}
                    </div>
                )
            })}
        </div>
    )
}

const Sandbox = () => {

    return <div>
        <SandpackLayout className="h-[900px] flex-col">
            <SandpackPreview showNavigator style={{ height: '600px' }} showOpenInCodeSandbox={false}>
            </SandpackPreview>
            <SandpackCodeEditor style={{ height: '300px' }} showLineNumbers={true} showRunButton={false} />
        </SandpackLayout>
    </div>
}
