import { ArrowDownIcon, ArrowUpIcon, LinkIcon } from '@heroicons/react/24/outline'
import { Gantt } from 'gantt-task-react'

import 'gantt-task-react/dist/index.css'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useWebSocket from 'react-use-websocket'
import moment from 'moment'

import Button from '../components/button'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'

import CheckboxField from '../forms/fields/checkbox-field'
import SelectField from '../forms/fields/select-field'
import TextField from '../forms/fields/text-field'
import FormModal from '../forms/form-modal'

import { api, api_delete } from '../api.service'
import { PAYMENT_STATUS, PROJECT_STAGE, StatusReminder, WORK_STATUS } from './admin-project-statuses'

export default function AdminGantt() {
    const [data, setData] = useState(null)
    const [projects, setProjects] = useState(null)
    const [isFiltered, setFiltered] = useState(true)
    const [tasks, setTasks] = useState([])
    const [selected, setSelected] = useState(null)
    const navigate = useNavigate()

    const { lastMessage } = useWebSocket(process.env.REACT_APP_WS_URL)

    useEffect(() => loadData(), [])

    // Live data
    useEffect(() => {
        if (lastMessage?.data?.includes('gantt')) {
            loadData()
        }
    }, [lastMessage])

    // Filter tasks
    useEffect(() => {
        if (!data) {
            setTasks([])
            return
        }

        let _tasks

        if (isFiltered) {
            _tasks = filterTasks(data, projects ?? [])
        } else {
            _tasks = data
        }

        setTasks(
            _tasks.map((x) => {
                return { id: x._id, ...x.data, start: moment(x.data.start).startOf('day').toDate(), end: moment(x.data.end).startOf('day').toDate() }
            })
        )
    }, [data, projects, isFiltered])

    // horrific hack
    useEffect(() => {
        // only show mondays in column headers
        const elements = document.querySelectorAll('.calendar > text')

        elements.forEach((el) => {
            try {
                if (el.innerHTML.startsWith('Mon')) {
                    el.innerHTML = el.innerHTML.split(' ')[1] // Date Only
                } else {
                    el.setAttribute('hidden', 'true')
                }
            } catch (e) {
                console.log(e)
            }
        })
    }, [tasks])

    const loadData = () => {
        api(`${process.env.REACT_APP_API_URL}/admin/gantt`).then((x) => setData(x))
        api(`${process.env.REACT_APP_API_URL}/admin/project`).then((x) => setProjects(x))
    }
    const updateItem = (item) => {
        api(`${process.env.REACT_APP_API_URL}/admin/gantt`, { data: { _id: item.id, data: item } })
    }
    const deleteItem = (item) => {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/gantt/${item.id}`).then((x) => navigate(0))
    }
    const addItem = () => {
        api(`${process.env.REACT_APP_API_URL}/admin/gantt`, { data: { data: { name: 'New Item', displayOrder: Math.max(...tasks.map((d) => d.displayOrder)) + 1, start: new Date(), end: moment().add(2, 'day').toDate(), progress: 0, isDisabled: false, type: 'task' } }, projectId: null }).then((x) => navigate(0))
    }

    function changeOrder(itemPosition, direction) {
        let _tasks = [...tasks]
        let x = Number(itemPosition)

        let target = _tasks.findIndex((d) => d.displayOrder === x)
        let swap

        if (direction === 'up') {
            swap = _tasks.findIndex((d) => d.displayOrder === x - 1)
            _tasks[target].displayOrder = x - 1
        } else if (direction === 'down') {
            swap = _tasks.findIndex((d) => d.displayOrder === x + 1)
            _tasks[target].displayOrder = x + 1
        }

        _tasks[swap].displayOrder = x

        updateItem(_tasks[target])
        updateItem(_tasks[swap])

        // Defer to SOT on server
        // navigate(0)

        // Keep live state, for faster movement
        setSelected(_tasks[target])
        setTasks(_tasks)
    }

    /*
        Render
    */
    if (!data || !projects) return <Loading></Loading>

    return (
        <>
            <FormModal open={!!selected} setOpen={() => setSelected(null)}>
                {selected && (
                    <>
                        <div className='grid grid-cols-2'>
                            <SelectField
                                label='Project'
                                options={projects
                                    .filter((x) => !x.isArchived)
                                    .map((x) => {
                                        return { value: x._id, text: x.projectName }
                                    })}
                                value={selected.projectId}
                                onChange={(projectId) => setSelected(attachProject(projectId, selected, projects))}
                            ></SelectField>
                            <TextField label='Item Name' value={selected.name} onChange={(val) => setSelected({ ...selected, name: val })}></TextField>
                            {/* <TextField label="Item Name" value={selected.name.split(" - ")[1]} onChange={(val) => setSelected({ ...selected, name: [projects.find(x => x._id === selected.projectId)?.projectName, val].join(" - ") })}></TextField> */}
                        </div>
                        {selected.projectId && (
                            <a href={`/admin/project/${selected.projectId}`} className='inline-flex py-4 align-baseline'>
                                View Project Summary <LinkIcon height={20} width={20} className='pl-1' />
                            </a>
                        )}
                        {/* <TextField label='Display Order' value={selected.displayOrder} onChange={(val) => setSelected({ ...selected, displayOrder: val })}></TextField> */}
                        <div className='flex justify-between mt-5'>
                            <Button
                                text='Save'
                                onClick={() => {
                                    updateItem(selected)
                                    setSelected(false)
                                }}
                            ></Button>
                            <div className='flex space-x-4'>
                                {Number(selected.displayOrder) > 0 && (
                                    <div className='flex items-center px-2 py-1 text-base rounded cursor-pointer select-none ring-1 ring-gray-600/50' onClick={() => changeOrder(selected.displayOrder, 'up')}>
                                        Shift Up
                                        <ArrowUpIcon className='w-6 h-6' />
                                    </div>
                                )}
                                {Number(selected.displayOrder) < tasks.length && (
                                    <div className='flex items-center px-2 py-1 text-base rounded cursor-pointer select-none ring-1 ring-gray-600/50' onClick={() => changeOrder(selected.displayOrder, 'down')}>
                                        Shift Down
                                        <ArrowDownIcon className='w-6 h-6' />
                                    </div>
                                )}
                                {Number(selected.displayOrder) > 0 && (
                                    <div className='flex items-center px-2 py-1 text-base rounded cursor-pointer select-none ring-1 ring-gray-600/50' onClick={() => changeOrder(selected.displayOrder, 'up')}>
                                        Shift Up
                                        <ArrowUpIcon className='w-6 h-6' />
                                    </div>
                                )}
                                {Number(selected.displayOrder) < tasks.length && (
                                    <div className='flex items-center px-2 py-1 text-base rounded cursor-pointer select-none ring-1 ring-gray-600/50' onClick={() => changeOrder(selected.displayOrder, 'down')}>
                                        Shift Down
                                        <ArrowDownIcon className='w-6 h-6' />
                                    </div>
                                )}
                            </div>
                            <Button
                                type='danger'
                                text='Delete'
                                onClick={() => {
                                    deleteItem(selected)
                                    setSelected(false)
                                }}
                            ></Button>
                        </div>
                    </>
                )}
            </FormModal>

            <PageHeader
                headline={'Gantt'}
                actions={
                    <div className='flex space-x-2'>
                        <CheckboxField label='Hide completed projects' value={isFiltered} onChange={(val) => setFiltered(val)} className='flex flex-row items-center space-x-2'></CheckboxField>
                        <Button text='Add Item' onClick={() => addItem()}></Button>
                    </div>
                }
            ></PageHeader>

            <div>Double click to edit item</div>
            <div className='flex flex-row items-center'>
                <b className='text-sm text-neutral-600'>Colours</b>
                <StatusReminder />
            </div>

            {tasks.length > 0 && (
                <>
                    <Gantt
                        tasks={tasks}
                        onProgressChange={updateItem}
                        onExpanderClick={updateItem}
                        onDateChange={updateItem}
                        onDoubleClick={(e) => setSelected(e)}
                        viewDate={new Date()}
                        columnWidth={10}
                        listCellWidth={''}
                        rowHeight={30}
                        // headerHeight={0}
                        TooltipContent={({ task }) => TooltipContent({ task: task, project: projects.find((d) => d._id === task?.projectId) })}
                    />
                </>
            )}
        </>
    )
}

/*
  Helpers
*/
function filterTasks(tasks, projects) {
    let project
    return tasks.filter((task) => {
        project = projects.find((proj) => task.data.projectId === proj._id)
        // Hide projects if "Live" && "Delivered" && "Paid"
        if (project?.projectStage === 5 && project?.workStatus === 3 && project?.paymentStatus === 2) {
            return false
        }
        return true
    })
}

function attachProject(projectId, task, projects) {
    const project = projects.find((x) => x._id === projectId)
    const stage = PROJECT_STAGE.find((x) => x.value === project.projectStage) ?? PROJECT_STAGE[0]

    return {
        ...task,
        projectId: projectId,
        styles: {
            ...task.styles,
            backgroundColor: stage.hex ?? '#b8c2cc',
            backgroundSelectedColor: stage.hex ?? '#b8c2cc',
            progressColor: '#ffffff55',
            progressSelectedColor: '#ffffff55',
        },
        progress: Number(stage.value) < 3 ? 100 : 0, // hack the progress bar as an opacity filter
    }
}

/*
  Sub-Component
*/
const TooltipContent = ({ task, project }) => {
    return (
        <div className='p-4 bg-white rounded-md ring ring-gray-800'>
            <b className='text-lg font-semibold'>{`${task.name}`}</b>
            <div className='flex flex-row flex-wrap justify-between'>
                <p className='pr-4'>{prettyDateSpan(task.start, task.end)}</p>
                <p>{`(${task.end.getTime() - task.start.getTime() !== 0 && ~~((task.end.getTime() - task.start.getTime()) / (1000 * 60 * 60 * 24))} days)`}</p>
            </div>
            {project && (
                <div className='grid grid-cols-2 gap-2 mt-4'>
                    <dt className='inline text-gray-500'>Project Stage</dt> <dd className={`px-2 py-1 text-m h-min font-medium break-all text-${PROJECT_STAGE.find((d) => d.value === project.projectStage)?.colour} rounded-md ring-1 ring-inset ring-${PROJECT_STAGE.find((d) => d.value === project.projectStage)?.colour ?? 'gray-300'}`}>{PROJECT_STAGE.find((d) => d.value === project.projectStage)?.text ?? 'N/A'}</dd>
                    <dt className='inline text-gray-500'>Work Status</dt> <dd className={`px-2 py-1 text-m h-min font-medium break-all text-${WORK_STATUS.find((d) => d.value === project.workStatus)?.colour} rounded-md ring-1 ring-inset ring-${WORK_STATUS.find((d) => d.value === project.workStatus)?.colour ?? 'gray-300'}`}>{WORK_STATUS.find((d) => d.value === project.workStatus)?.text ?? 'N/A'}</dd>
                    <dt className='inline text-gray-500'>Payment Status</dt> <dd className={`px-2 py-1 text-m h-min font-medium break-all text-${PAYMENT_STATUS.find((d) => d.value === project.paymentStatus)?.colour} rounded-md ring-1 ring-inset ring-${PAYMENT_STATUS.find((d) => d.value === project.paymentStatus)?.colour ?? 'gray-300'}`}>{PAYMENT_STATUS.find((d) => d.value === project.paymentStatus)?.text ?? 'N/A'}</dd>
                </div>
            )}
        </div>
    )
}

function prettyDateSpan(start, end) {
    let dt_start = moment(start)
    let dt_end = moment(end)
    let output = dt_start.format('Do')
    if (dt_start.month() !== dt_end.month()) {
        output += ` ${dt_start.format('MMM')}`
        if (dt_start.year() !== dt_end.year()) {
            output += ` ${dt_start.format('YYYY')}`
        }
    }
    output += ` - ${dt_end.format('Do MMM YYYY')}`
    return output
}

// const CustomHeader = ({ tasks }) => {
//   const [ticks, setTicks] = useState(null)

//   useEffect(() => {
//     let start = tasks.map(x => x.start).reduce((min, x) => min = (x.valueOf < min.valueOf ? x : min), new Date())
//     let end = tasks.map(x => x.end).reduce((max, x) => max = (x.valueOf > max.valueOf ? x : max), new Date())

//     // create array of days between task date span
//     let _ticks = []
//     let dt = start.valueOf() - 86400000
//     while (dt <= end.valueOf()) {
//       _ticks.push(moment(dt))
//       dt += 86400000
//     }

//     setTicks(_ticks)
//   }, [tasks])

//   return (
//     <svg>
//       {/* Months */}
//       <g>
//         {ticks.filter((d,i) => {
//           if (i === 0) return true
//           return d.month() !== ticks[i-1].month()
//         }).map(month => (
//           <g>
//             <line />
//             <text>{month.format("MMMM")}</text>
//           </g>
//         ))}
//       </g>

//       {/* Mondays */}
//       <g></g>
//     </svg>
//   )
// }
