import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export default function WysiwygField({ label, value, onChange, className, inputClass, labelClass, placeholder }) {
    return (
        <div className={className}>
            <label className={`block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>{label}</label>
            <div className='mt-2'>
                <Editor
                    initialContentState={!value ? { blocks: [], entityMap: {} } : !value.entityMap ? { entityMap: {}, ...value } : value}
                    editorClassName='bg-white border px-3 rounded-md'
                    onContentStateChange={(e) => {
                        onChange(e)
                    }}
                />
            </div>
        </div>
    )
}
