import { useNavigate, useParams } from 'react-router-dom'

import Button from '../components/button'
import PageHeader from '../components/page-header'

import CheckboxField from './fields/checkbox-field'
import DateField from './fields/date-field'
import ImageField from './fields/image-upload'
import PasswordField from './fields/password-field'
import SelectField from './fields/select-field'
import TextAreaField from './fields/text-area-field'
import TextField from './fields/text-field'
import FormWrapper from './form-wrapper'

export default function UserForm(props) {
    const params = useParams()
    let id = params.id ?? props.id
    const navigate = useNavigate()
    return (
        <div>
            <PageHeader headline='Manage User'></PageHeader>
            <FormWrapper url='admin/user' id={id} {...props} callback={() => navigate('/admin/users')}>
                {(values, setValues) => (
                    <>
                        <div className='grid grid-cols-2 gap-2'>
                            <TextField label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                            <TextField label='Email' value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>
                        </div>
                        <hr className='my-3' />
                        <div className='grid grid-cols-2 gap-2'>
                            <PasswordField label='New Password' value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
                        </div>
                    </>
                )}
            </FormWrapper>
        </div>
    )
}
