import { TrashIcon } from '@heroicons/react/24/outline'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '../components/button'
import PageHeader from '../components/page-header'
import StatusBox from '../components/status-box'

import { PROJECT_STAGE } from '../admin/admin-project-statuses'
import { api } from '../api.service'
import CheckboxField from './fields/checkbox-field'
import CurrencyField from './fields/currency-field'
import DateField from './fields/date-field'
import DateTimeField from './fields/datetime-field'
import FileField from './fields/file-field'
import ImageField from './fields/image-upload'
import SelectField from './fields/select-field'
import TextAreaField from './fields/text-area-field'
import TextField from './fields/text-field'
import WysiwygField from './fields/wysiwyg-field'
import FormWrapper from './form-wrapper'

const PageDefaults = [
    { name: 'Login', description: 'A standard login page.' },
    { name: 'Register', description: 'A standard register page.' },
    { name: 'Landing', description: 'A standard landing page to display information about the application, and provide links to the login & register page.' },
]

const FeatureDefaults = [
    { name: 'User Accounts', description: 'Users can register and sign into the application.' },
    { name: 'Payment Gateway', description: 'Users can purchase goods or services on the site.' },
    { name: 'Emails', description: 'The application will send emails to users to administrators when certain actions take place.' },
]

export default function ProjectForm(props) {
    const params = useParams()
    let id = params.id ?? props.id
    const navigate = useNavigate()

    return (
        <FormWrapper noStyle hideButton url='admin/project' id={id} {...props} callback={() => navigate(id === 'new' ? `/admin/projects` : `/admin/project/${id}`)}>
            {(values, setValues, submit) => (
                <div className='flex flex-col h-screen'>
                    <PageHeader headline='Manage Project' actions={<Button text={'Save'} onClick={() => submit()}></Button>}></PageHeader>
                    <div className='flex-1 overflow-y-auto'>
                        {/* Overview */}
                        <div className='max-w-6xl p-10 m-5 mx-auto bg-white rounded-lg shadow-md'>
                            {values.projectStage != 0 && <ImageField label='Logo' value={values.logo} onChange={(val) => setValues({ ...values, logo: val })}></ImageField>}
                            <div className='grid grid-cols-2 gap-4'>
                                <TextField label='Project Name' value={values.projectName} onChange={(val) => setValues({ ...values, projectName: val })}></TextField>
                                <div className='flex flex-col float-end'>
                                    <label className={`block text-sm font-medium leading-6 text-gray-900`}>Project Stage</label>
                                    <StatusBox
                                        status={values.projectStage ?? null}
                                        statuses={PROJECT_STAGE}
                                        change={(val) => {
                                            console.log(values)
                                            setValues({ ...values, projectStage: Number(val) })
                                        }}
                                    ></StatusBox>
                                </div>
                            </div>
                            <DateField label='Intro Call Date' value={values.dateIntroCall} onChange={(val) => setValues({ ...values, dateIntroCall: val })}></DateField>
                            <br />
                            <TextAreaField label='Notes' value={values.notes} onChange={(val) => setValues({ ...values, notes: val })}></TextAreaField>
                        </div>

                        {/* Client Details */}
                        <div className='max-w-6xl p-10 m-5 mx-auto bg-white rounded-lg shadow-md'>
                            <div className='font-semibold text-neutral-800'>Client Details</div>
                            <div className='grid grid-cols-2 gap-4 my-2'>
                                <TextField label='Name(s)' value={values.clientName} onChange={(val) => setValues({ ...values, clientName: val })}></TextField>
                                <TextField label='Email' value={values.clientEmail} onChange={(val) => setValues({ ...values, clientEmail: val })}></TextField>
                                <TextField label='Phone' value={values.clientPhone} onChange={(val) => setValues({ ...values, clientPhone: val })}></TextField>
                                <TextField label='Location' value={values.location} onChange={(val) => setValues({ ...values, location: val })}></TextField>
                                <TextField label='Source' value={values.source} onChange={(val) => setValues({ ...values, source: val })}></TextField>
                                <SelectField optionListName={'country'} label='Country' value={values.country ?? 0} onChange={(val) => setValues({ ...values, country: val })}></SelectField>
                                <CurrencyField label='Budget' value={values.budget} onChange={(val) => setValues({ ...values, budget: val })}></CurrencyField>
                                <CurrencyField label='Price' value={values.price} onChange={(val) => setValues({ ...values, price: val })}></CurrencyField>
                                <CurrencyField label='Customer Acquisition Cost' value={values.customerAcquisitionCost} onChange={(val) => setValues({ ...values, customerAcquisitionCost: val })}></CurrencyField>
                            </div>
                        </div>

                        {/* Confirmed Project */}
                        {values.projectStage != 0 && (
                            <>
                                {/* Project Overview */}
                                <div className='max-w-6xl p-10 m-5 mx-auto bg-white rounded-lg shadow-md'>
                                    <div className='font-semibold text-neutral-800'>Project Details</div>
                                    <div className='grid grid-cols-2 gap-4 my-2'>
                                        <SelectField optionListName={'workStatus'} label='Work Status' value={values.workStatus ?? 0} onChange={(val) => setValues({ ...values, workStatus: val })}></SelectField>
                                        <SelectField optionListName={'paymentStatus'} label='Payment Status' value={values.paymentStatus ?? 0} onChange={(val) => setValues({ ...values, paymentStatus: val })}></SelectField>
                                        <TextField label='URL' value={values.url} onChange={(val) => setValues({ ...values, url: val })}></TextField>
                                        <SelectField optionListName={'users'} label='User' value={values.user ?? null} onChange={(val) => setValues({ ...values, user: val })}></SelectField>
                                    </div>
                                    <TextAreaField label='Scope Overview' value={values.scopeOverview} onChange={(val) => setValues({ ...values, scopeOverview: val })}></TextAreaField>
                                </div>

                                {/* Pages */}
                                <div className='max-w-6xl p-10 m-5 mx-auto bg-white rounded-lg shadow-md'>
                                    <div className='font-semibold text-neutral-800'>Pages</div>

                                    {values.pages?.map((x, i) => (
                                        <div key={i} className='relative p-3 my-2 bg-neutral-100'>
                                            <div onClick={() => setValues({ ...values, pages: values.pages.filter((y, j) => j !== i) })} className='absolute z-20 right-3 text-neutral-600'>
                                                <TrashIcon className='w-6 h-6'></TrashIcon>
                                            </div>
                                            <TextField label='Name' value={x.name} onChange={(val) => setValues({ ...values, pages: values.pages.map((y, j) => (j === i ? { ...y, name: val } : y)) })}></TextField>
                                            <TextAreaField label='Description' value={x.description} onChange={(val) => setValues({ ...values, pages: values.pages.map((y, j) => (j === i ? { ...y, description: val } : y)) })}></TextAreaField>
                                        </div>
                                    ))}
                                    <div className='grid grid-cols-6 gap-2'>
                                        {PageDefaults.map((x) => (
                                            <>{!values.pages?.find((y) => y.name == x.name) && <Button key={x.name} className='m-3' text={`+ ${x.name}`} onClick={() => setValues({ ...values, pages: values.pages ? values.pages.concat([x]) : [x] })}></Button>}</>
                                        ))}
                                    </div>
                                    <Button className='my-3' text='Add Custom Page' onClick={() => setValues({ ...values, pages: values.pages ? values.pages.concat([{}]) : [{}] })}></Button>
                                </div>

                                {/* Features */}
                                <div className='max-w-6xl p-10 m-5 mx-auto bg-white rounded-lg shadow-md'>
                                    <div className='font-semibold text-neutral-800'>Features</div>
                                    {values.features?.map((x, i) => (
                                        <div key={i} className='relative p-3 my-2 bg-neutral-100'>
                                            <div onClick={() => setValues({ ...values, features: values.features.filter((y, j) => j !== i) })} className='absolute z-20 right-3 text-neutral-600'>
                                                <TrashIcon className='w-6 h-6'></TrashIcon>
                                            </div>
                                            <TextField label='Name' value={x.name} onChange={(val) => setValues({ ...values, features: values.features.map((y, j) => (j === i ? { ...y, name: val } : y)) })}></TextField>
                                            <TextAreaField label='Description' value={x.description} onChange={(val) => setValues({ ...values, features: values.features.map((y, j) => (j === i ? { ...y, description: val } : y)) })}></TextAreaField>
                                        </div>
                                    ))}
                                    <div className='grid grid-cols-6 gap-2'>
                                        {FeatureDefaults.map((x) => (
                                            <>{!values.features?.find((y) => y.name == x.name) && <Button key={x.name} className='m-3' text={`+ ${x.name}`} onClick={() => setValues({ ...values, features: values.features ? values.features.concat([x]) : [x] })}></Button>}</>
                                        ))}
                                    </div>
                                    <Button className='my-3' text='Add Feature' onClick={() => setValues({ ...values, features: values.features ? values.features.concat([{}]) : [{}] })}></Button>
                                </div>

                                {/* Custom Sections */}
                                <div className='max-w-6xl p-10 m-5 mx-auto bg-white rounded-lg shadow-md'>
                                    <div className='font-semibold text-neutral-800'>Custom Sections</div>

                                    {values.customSections?.map((x, i) => (
                                        <div key={i} className='relative p-3 my-2 bg-neutral-100'>
                                            <div onClick={() => setValues({ ...values, customSections: values.customSections.filter((y, j) => j !== i) })} className='absolute z-20 right-3 text-neutral-600'>
                                                <TrashIcon className='w-6 h-6'></TrashIcon>
                                            </div>

                                            <TextField label='Title' value={x.title} onChange={(val) => setValues({ ...values, customSections: values.customSections.map((y, j) => (j === i ? { ...y, title: val } : y)) })}></TextField>
                                            <TextAreaField label='Text' value={x.text} onChange={(val) => setValues({ ...values, customSections: values.customSections.map((y, j) => (j === i ? { ...y, text: val } : y)) })}></TextAreaField>
                                        </div>
                                    ))}
                                    <Button className='my-3' text='Add Custom Section' onClick={() => setValues({ ...values, customSections: values.customSections ? values.customSections.concat([{}]) : [{}] })}></Button>
                                </div>

                                {/* Colours */}
                                <div className='max-w-6xl p-10 m-5 mx-auto bg-white rounded-lg shadow-md'>
                                    <div className='font-semibold text-neutral-800'>Colours</div>
                                    {values.colours?.map((x, i) => (
                                        <div key={i} className='relative grid grid-cols-2 gap-4'>
                                            <div onClick={() => setValues({ ...values, colours: values.colours.filter((y, j) => j !== i) })} className='absolute right-0 z-20 text-neutral-600'>
                                                <TrashIcon className='w-6 h-6'></TrashIcon>
                                            </div>

                                            <TextField label='Name' value={x.name} onChange={(val) => setValues({ ...values, colours: values.colours.map((y, j) => (j === i ? { ...y, name: val } : y)) })}></TextField>
                                            <TextField label='Hex' value={x.hex} onChange={(val) => setValues({ ...values, colours: values.colours.map((y, j) => (j === i ? { ...y, hex: val } : y)) })}></TextField>
                                        </div>
                                    ))}
                                    <Button className='my-3' text='Add Colour' onClick={() => setValues({ ...values, colours: values.colours ? values.colours.concat([{}]) : [{}] })}></Button>
                                </div>

                                {/* Domain */}
                                <div className='max-w-6xl p-10 m-5 mx-auto bg-white rounded-lg shadow-md'>
                                    <WysiwygField label='Domain Details' value={values.domainInstructions} onChange={(val) => setValues({ ...values, domainInstructions: val })}></WysiwygField>
                                </div>

                                {/* Contracts */}
                                <div className='max-w-6xl p-10 m-5 mx-auto bg-white rounded-lg shadow-md'>
                                    <div className='font-semibold text-neutral-800'>Contracts</div>
                                    {values.contracts?.map((x, i) => (
                                        <div key={i} className='relative p-3 my-2 border'>
                                            <div onClick={() => setValues({ ...values, contracts: values.contracts.filter((y, j) => j !== i) })} className='absolute right-0 z-20 text-neutral-600'>
                                                <TrashIcon className='w-6 h-6'></TrashIcon>
                                            </div>
                                            <TextField label='Name' value={x.name} onChange={(val) => setValues({ ...values, contracts: values.contracts.map((y, j) => (j === i ? { ...y, name: val } : y)) })}></TextField>
                                            <FileField label='File' value={x.file} onChange={(val) => setValues({ ...values, contracts: values.contracts.map((y, j) => (j === i ? { ...y, file: val } : y)) })}></FileField>
                                            {x.signed && (
                                                <a href={`${process.env.REACT_APP_FILE_URL}/${x.signed}`} target='_blank'>
                                                    Signed Contract ({x.signed})
                                                </a>
                                            )}
                                        </div>
                                    ))}
                                    <Button className='my-3' text='Add Contract' onClick={() => setValues({ ...values, contracts: values.contracts ? values.contracts.concat([{}]) : [{}] })}></Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </FormWrapper>
    )
}
