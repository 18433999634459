function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Tabs({ tabs, activeIdx, onChange }) {
    return (
        <>
            <div className='p-2 bg-white select-none md:hidden'>
                <label htmlFor='tabs' className='sr-only'>
                    Select a tab
                </label>
                <select
                    id='tabs'
                    name='tabs'
                    className='block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-green-500 focus:outline-none focus:ring-green-500 md:text-sm'
                    defaultValue={tabs.find((_, idx) => idx == activeIdx)?.name}
                    onChange={({ target: { value } }) => {
                        console.log(value)
                        onChange(Number(value))
                    }}
                >
                    {tabs.map((tab, idx) => (
                        <option key={tab.name} value={idx}>
                            {tab.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className='hidden bg-white md:block'>
                <div className='px-8 border-b border-gray-200'>
                    <nav className='flex -mb-px space-x-8' aria-label='Tabs'>
                        {tabs.map((tab, idx) => (
                            <span key={tab.name} onClick={() => onChange(idx)} className={classNames(idx == activeIdx ? 'border-green-500 text-green-600' : 'cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium whitespace-nowrap')} aria-current={idx == activeIdx ? 'page' : undefined}>
                                {tab.icon && <tab.icon className={classNames(idx == activeIdx ? 'text-green-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5')} aria-hidden='true' />}
                                <span>{tab.name}</span>
                            </span>
                        ))}
                    </nav>
                </div>
            </div>
        </>
    )
}
