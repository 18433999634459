import { MinusIcon, PaperAirplaneIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { ArrowsPointingInIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

import Button from '../components/button'
import CodeEditor from '../components/code-editor'

import { TextAreaField } from '../forms/fields'
import CheckboxField from '../forms/fields/checkbox-field'
import TextField from '../forms/fields/text-field'
import FormModal from '../forms/form-modal'

import { api } from '../api.service'

export default function ChatBot() {
    const [outgoingMessage, setOutgoingMessage] = useState('')
    const [conversation, setConversation] = useState([])
    const [rememberConversation, setRememberConversation] = useState(true)
    const [showContextModal, setShowContextModal] = useState(false)
    const [context, setContext] = useState({ text: [], code: [] })
    const [isExpanded, setIsExpanded] = useState(false)
    const [isMinimised, setIsMinimised] = useState(true)
    const [isSending, setIsSending] = useState(false)
    const chatRef = useRef(null)

    const codeSnippetRegex = /([^`]*)```(\w+)\s*([\s\S]*?)```([^`]*)/

    const handleSendMessage = (message) => {
        if (!isSending) {
            const previousConversation = conversation.filter((exchange) => exchange.memorised)

            setIsSending(true)
            api(`${process.env.REACT_APP_API_URL}/generate/bot-chat`, { message, previousConversation, context: [...context.code, ...context.text] }).then(([response]) => {
                setConversation([...conversation, { user: outgoingMessage, assistant: response.message.content, memorised: rememberConversation }])
                setOutgoingMessage('')
                setIsSending(false)
            })
        }
    }

    const SanitisedResponse = (response) => {
        const codeMatch = response.match(codeSnippetRegex)

        if (codeMatch) {
            const [fullMatch, preText, language, content, postText] = codeMatch

            return (
                <p className='w-auto p-2 mr-2 text-left break-words bg-blue-200 rounded-md'>
                    {preText}
                    <SyntaxHighlighter language={language}>{content}</SyntaxHighlighter>
                    {postText}
                </p>
            )
        }

        return <p className='w-auto p-2 mr-2 text-left break-words bg-blue-200 rounded-md'>{response}</p>
    }

    const scrollToBottom = () => {
        if (chatRef.current) {
            const chat = chatRef.current
            chat.scrollTop = chat.scrollHeight
        }
    }

    const forgetChat = () => {
        setConversation([])
    }

    useEffect(() => {
        scrollToBottom()
    }, [conversation])

    const ContextModal = () => {
        const [contextType, setContextType] = useState('text')
        const [text, setText] = useState('')
        const [code, setCode] = useState('')

        return (
            <div className='z-50 '>
                {/* replace buttons with slider when converting to TS */}
                <Button text='text' onClick={() => setContextType('text')} className='mr-2' />
                <Button text='code' onClick={() => setContextType('code')} />
                {contextType === 'text' && (
                    <>
                        <TextAreaField value={text} onChange={(value) => setText(value)} className='my-2' />
                        <Button
                            text='Add context'
                            onClick={() => {
                                setContext({ ...context, text: [...context.text, text] })
                            }}
                        />
                    </>
                )}

                {contextType === 'code' && (
                    <>
                        <CodeEditor className='my-2 h-96' value={code} onChange={(value) => setCode(value)} />
                        <Button
                            text='Add context'
                            onClick={() => {
                                setContext({ ...context, code: [...context.code, code] })
                            }}
                        />
                    </>
                )}
            </div>
        )
    }

    return (
        <div>
            {isMinimised ? (
                <div className='fixed z-40 text-green-600 bottom-8 right-8' onClick={() => setIsMinimised(false)}>
                    <QuestionMarkCircleIcon className='h-16' />
                </div>
            ) : (
                <div className={`fixed z-40 flex flex-col justify-end border-2 rounded-lg bg-green-50 border-blue-600 p-4  ${isExpanded ? 'w-full h-full ' : 'w-1/3 h-2/3 pt-10 bottom-4 right-4'}`}>
                    <FormModal open={showContextModal} setOpen={setShowContextModal}>
                        <ContextModal />
                    </FormModal>

                    <div className='absolute flex justify-between w-full top-2 left-2'>
                        {isExpanded ? <ArrowsPointingInIcon className='h-6 mb-2' onClick={() => setIsExpanded(false)} /> : <ArrowsPointingOutIcon className='h-6 mb-2' onClick={() => setIsExpanded(true)} />}
                        <MinusIcon className='h-6 pr-4' onClick={() => setIsMinimised(true)} />
                    </div>

                    <div className='w-full overflow-y-scroll ' ref={chatRef}>
                        {conversation.length > 0 &&
                            conversation.map((exchange) => {
                                return (
                                    <div>
                                        <p className='p-2 mr-2 text-right break-words bg-gray-200 rounded-md max-w-content'>{exchange.user}</p>
                                        <br />
                                        <div>
                                            {SanitisedResponse(exchange.assistant)}
                                            {!exchange.memorised && <p className='italic text-red-300'>The bot will not remember this exchange</p>}
                                        </div>
                                        <br />
                                    </div>
                                )
                            })}
                    </div>
                    <div className='flex flex-col p-4 mt-2 bg-green-200 rounded-md'>
                        <div className='flex items-center bg-white'>
                            <TextField
                                value={outgoingMessage}
                                className='inline-block w-full '
                                disabled={isSending}
                                placeholder='Talk to the bot!'
                                onChange={(e) => {
                                    setOutgoingMessage(e)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSendMessage(outgoingMessage)
                                    }
                                }}
                            />
                            <PaperAirplaneIcon
                                className='h-6 mr-2'
                                onClick={() => {
                                    handleSendMessage(outgoingMessage)
                                }}
                            />
                        </div>

                        <div className='flex my-2'>
                            <Button
                                className='w-auto h-auto mr-2 bg-red-800 '
                                text='Clear and forget conversation'
                                onClick={() => {
                                    forgetChat()
                                }}
                            />
                            <Button
                                text='Add context'
                                className='bg-purple-600'
                                onClick={() => {
                                    setShowContextModal(true)
                                }}
                            />
                        </div>

                        <CheckboxField
                            value={rememberConversation}
                            label='Remember conversation'
                            inline
                            onChange={(e) => {
                                setRememberConversation(e)
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
