import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import logo from '../logo.png'

export default function ResetPassword() {
    const [values, setValues] = useState({})
    const [error, setError] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setValues({ ...values, token: searchParams.get('token'), id: searchParams.get('id') })
    }, [])

    const submit = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(values),
        })
            .then((res) => {
                return res.json()
            })
            .then((x) => {
                if (x.status === 'success') {
                    window.location.replace('/login')
                } else {
                    setError('Login failed. Please check your details and try again.')
                }
            })
    }

    return (
        <>
            <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
                <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                    <img className='mx-auto h-44 w-auto' src={logo} alt='Your Company' />
                    <h2 className='mt-24 text-center text-3xl font-bold tracking-tight text-green-500'>Reset Password</h2>
                </div>

                <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
                    <div className='bg-white py-8 px-4  sm:rounded-lg sm:px-10'>
                        <div className='space-y-6'>
                            <div>
                                <label className='block text-sm font-medium text-green-700 text-center'>{error}</label>
                            </div>

                            <div>
                                <label htmlFor='password' className='block text-sm font-medium text-green-700'>
                                    New Password
                                </label>
                                <div className='mt-1'>
                                    <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })} id='password' name='password' type='password' autoComplete='current-password' required className='block w-full appearance-none rounded-md border border-green-300 px-3 py-2 placeholder-green-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm' />
                                </div>
                            </div>

                            <div>
                                <button onClick={() => submit()} type='button' className='flex w-full justify-center rounded-md border border-transparent bg-green-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
