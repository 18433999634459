export default function DateField({ label, value, onChange, className, inputClass, labelClass, onBlur }) {
    if (value?.length > 11) value = value.split('T')[0]

    return (
        <div className={className}>
            <label className={`block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>{label}</label>
            <div className='mt-2'>
                <input onBlur={onBlur} type='date' value={value} onChange={(e) => onChange(e.target.value)} className={`block rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 ${inputClass}`} />
            </div>
        </div>
    )
}
