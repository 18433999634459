import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import draftToHtml from 'draftjs-to-html'

import Button from '../components/button'
import Confirm from '../components/confirm'
import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

import TextAreaField from '../forms/fields/text-area-field'

import { api, api_delete } from '../api.service'
import logo from '../logo.png'

export default function Analytics() {
    const [initData, setInitData] = useState(null)
    const [data, setData] = useState(null)

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/project`).then((x) => {
            setData(x)
            setInitData(x)
        })
    }, [])
    const submit = () => {
        api(`${process.env.REACT_APP_API_URL}/project/analytics`, { data }).then((x) => {
            setData(x)
            setInitData(x)
        })
    }

    if (!data) return <Loading></Loading>
    const rawContentState = data.domainInstructions ? (data.domainInstructions.entityMap ? data.domainInstructions : { entityMap: {}, ...data.domainInstructions }) : { blocks: [], entityMap: {} }
    const markup = draftToHtml(rawContentState)

    return (
        <>
            <PageHeader headline={'Analytics'}></PageHeader>

            <div className='flex flex-col max-w-6xl p-10 mx-auto mt-10 font-medium bg-white shadow text-neutral-800'>
                <div className='text-3xl font-bold text-center'>Connect an Analytics Tool</div>
                <div className='my-3 text-xl text-center text-neutral-700'>Analytics tools allow you to see how many users are using your application, where they are coming from, and how they are interacting with your application.</div>
                <div className='my-3 font-normal text-center text-neutral-700'>
                    Google Analytics is a very powerful free analytics tool that will provide you with lots of useful data.
                    <a className='block text-green-500 underline' target='_blank' href='https://analytics.google.com/'>
                        analytics.google.com
                    </a>
                </div>
                <div className='my-3 font-normal text-center text-neutral-700'>
                    Microsoft Clarity provides you with application heatmaps and screen recording so you can see exactly what your users are doing.
                    <a className='block text-green-500 underline' target='_blank' href='https://clarity.microsoft.com/'>
                        clarity.microsoft.com
                    </a>
                </div>
                <div className='my-3 font-normal text-center text-neutral-700'>
                    Pelatics is an easy to understand simplified analytics tool that provides insights and suggestions to improve the performance of your application.
                    <a className='block text-green-500 underline' target='_blank' href='https://pelatics.com'>
                        pelatics.com
                    </a>
                </div>
                <div className='my-5 text-center text-neutral-700'>Once you have selected and signed up for your chosen analytics tools you can enter the tracking codes below to integrate them into your MVP.</div>

                <TextAreaField label='Google Analytics Tracking Code' value={data.ga_code} onChange={(val) => setData({ ...data, ga_code: val })}></TextAreaField>
                <TextAreaField label='Microsoft Clarity Tracking Code' value={data.clarity_code} onChange={(val) => setData({ ...data, clarity_code: val })}></TextAreaField>
                <TextAreaField label='Pelatics Tracking Code' value={data.pelatics_code} onChange={(val) => setData({ ...data, pelatics_code: val })}></TextAreaField>
                <div className='inline mx-auto'>
                    <Button disabled={data === initData} className='mt-5' text='Save' onClick={() => submit()}></Button>
                </div>
            </div>
        </>
    )
}
