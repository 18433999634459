import { CalendarDaysIcon, EnvelopeIcon, GlobeAltIcon, InboxArrowDownIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import Button from '../components/button'
import Card from '../components/card'
import Confirm from '../components/confirm'
import Loading from '../components/loading'
import { ScopeDocumentViewer } from '../components/scope-document-viewer'

import FileField from '../forms/fields/file-field'

import { api } from '../api.service'
import { PAYMENT_STATUS, PROJECT_STAGE, WORK_STATUS } from './admin-project-statuses'

export default function ProjectSummary({ project }) {
    const navigate = useNavigate()
    const [deleteId, setDeleteId] = useState(false)

    function softDeleteProject() {
        api(`${process.env.REACT_APP_API_URL}/admin/project`, { data: { _id: project._id, isArchived: true } }).then((x) => navigate('/admin/projects'))
    }

    if (!project) {
        return <Loading></Loading>
    }

    return (
        <>
            <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => softDeleteProject()}></Confirm>

            <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
                <div className='grid items-start max-w-2xl grid-cols-1 mx-auto grid-rows-auto gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
                    {/* Status Card */}
                    <Card className='bg-white lg:col-start-3 lg:row-end-1' title='Status'>
                        <dl className='leading-6'>
                            <div className='grid grid-cols-2 py-2 lg:grid-cols-1 lg:grid-rows-2'>
                                <dt className='inline text-gray-500'>Project Stage</dt> <dd className={`px-2 py-1 text-m h-min font-medium break-all  text-${PROJECT_STAGE.find((d) => d.value === project.projectStage)?.colour} rounded-md ring-1 ring-inset ring-${PROJECT_STAGE.find((d) => d.value === project.projectStage)?.colour ?? 'gray-300'}`}>{PROJECT_STAGE.find((d) => d.value === project.projectStage)?.text ?? 'N/A'}</dd>
                            </div>
                            <div className='grid grid-cols-2 py-2 lg:grid-cols-1 lg:grid-rows-2'>
                                <dt className='inline text-gray-500'>Work Status</dt> <dd className={`px-2 py-1 text-m h-min font-medium break-all text-${WORK_STATUS.find((d) => d.value === project.workStatus)?.colour} rounded-md ring-1 ring-inset ring-${WORK_STATUS.find((d) => d.value === project.workStatus)?.colour ?? 'gray-300'}`}>{WORK_STATUS.find((d) => d.value === project.workStatus)?.text ?? 'N/A'}</dd>
                            </div>
                            <div className='grid grid-cols-2 py-2 lg:grid-cols-1 lg:grid-rows-2'>
                                <dt className='inline text-gray-500'>Payment Status</dt> <dd className={`px-2 py-1 text-m h-min font-medium break-all text-${PAYMENT_STATUS.find((d) => d.value === project.paymentStatus)?.colour} rounded-md ring-1 ring-inset ring-${PAYMENT_STATUS.find((d) => d.value === project.paymentStatus)?.colour ?? 'gray-300'}`}>{PAYMENT_STATUS.find((d) => d.value === project.paymentStatus)?.text ?? 'N/A'}</dd>
                            </div>
                        </dl>
                    </Card>

                    {/* Client Contact Card */}
                    <Card className='bg-white rounded-lg ring-1 ring-green-600 lg:col-start-3 lg:row-end-2' title={project?.clientName ?? 'Client'}>
                        <dl className='flex flex-wrap'>
                            <div className='flex flex-none w-full gap-x-4 '>
                                <dt className='flex-none'>
                                    <span className='sr-only'>Email</span>
                                    <EnvelopeIcon className='w-5 h-6 text-gray-400' aria-hidden='true' />
                                </dt>
                                <dd className='text-sm font-medium leading-6 text-gray-900 break-all'>
                                    {project.clientEmail ? (
                                        <a href={`mailto:${project.clientEmail}`} className='underline'>
                                            {project?.clientEmail}
                                        </a>
                                    ) : (
                                        'Unknown'
                                    )}
                                </dd>
                            </div>
                            {project.clientPhone && (
                                <div className='flex flex-none w-full mt-4 gap-x-4'>
                                    <dt className='flex-none'>
                                        <span className='sr-only'>Phone</span>
                                        <PhoneIcon className='w-5 h-6 text-gray-400' aria-hidden='true' />
                                    </dt>
                                    <dd className='text-sm font-medium leading-6 text-gray-900'>{project.clientPhone}</dd>
                                </div>
                            )}
                            <div className='flex flex-none w-full mt-4 gap-x-4'>
                                <dt className='flex-none'>
                                    <span className='sr-only'>Location</span>
                                    <GlobeAltIcon className='w-5 h-6 text-gray-400' aria-hidden='true' />
                                </dt>
                                <dd className='text-sm font-medium leading-6 text-gray-900'>{project?.location ?? 'Unknown'}</dd>
                            </div>
                            <div className='flex flex-none w-full mt-4 gap-x-4'>
                                <dt className='flex-none'>
                                    <InboxArrowDownIcon className='w-5 h-6 text-gray-400' aria-hidden='true' />
                                </dt>
                                <dd className='text-sm font-medium leading-6 text-gray-900'>
                                    <span className='text-gray-400'>Source:</span> {project?.source ?? 'Unknown'}
                                </dd>
                            </div>
                            <div className='flex flex-none w-full mt-4 gap-x-4'>
                                <dt className='flex-none'>
                                    <span className='sr-only'>Intro Call Date</span>
                                    <CalendarDaysIcon className='w-5 h-6 text-gray-400' aria-hidden='true' />
                                </dt>
                                <dd className='text-sm font-medium leading-6 text-gray-900'>
                                    <span className='text-gray-400'>Intro Call:</span> {moment(project.dateIntroCall).format('Do MMMM, YYYY')}
                                </dd>
                            </div>
                        </dl>
                    </Card>

                    {/* Scope */}
                    <Card className='bg-white lg:col-span-2 lg:row-span-4' title={`Scope${project.notes ? ' & Notes' : ''}`}>
                        {project.notes && <div className='mb-4 text-gray-500'>{project.notes}</div>}
                        <div className='-mx-2 -mb-2 sm:-mx-4 sm:-mb-4'>
                            <ScopeDocumentViewer data={project}></ScopeDocumentViewer>
                        </div>
                    </Card>

                    {/* Project Documents - Contracts, Client-provided docs etc. */}
                    <Card className='bg-white lg:col-start-3 lg:row-end-3' title='Documents'>
                        <dl>
                            {project.documents.map((x) => (
                                <div className='flex flex-row justify-between py-2 border-b border-gray-200'>
                                    <dt>
                                        <a className href={x.uri}>
                                            {x.documentName}
                                        </a>
                                    </dt>
                                    <dd className='text-sm font-medium leading-6 text-gray-900'>{x.dateUploaded.toLocaleString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</dd>
                                </div>
                            ))}
                            <FileField endpoint='project'></FileField>
                        </dl>
                    </Card>

                    {/* Danger Zone */}
                    <Card className='bg-white rounded-lg ring-1 ring-red-700 lg:col-span-3' title={<span className='text-red-700'>Danger Zone</span>}>
                        <div className='flex flex-col'>
                            <div className='w-fit'>
                                <Button type='danger' text='Delete Project' onClick={() => setDeleteId(project._id)}></Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}
