import { Dialog, Transition } from '@headlessui/react'
import { ArrowDownIcon, BoltIcon, CalendarDaysIcon, ClockIcon, CodeBracketIcon, ComputerDesktopIcon, CubeIcon, FolderIcon, RocketLaunchIcon, TableCellsIcon, UserCircleIcon, ViewColumnsIcon, WrenchIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

import logo from '../logo-dark.png'
import ChatBot from './admin-ai-chat'

const navigation = [
    { name: 'Dashboard', href: '/admin', icon: ComputerDesktopIcon },
    { name: 'Projects', href: '/admin/projects', icon: WrenchIcon },
    { name: 'System Users', href: '/admin/users', icon: UserCircleIcon },
    { name: 'Gantt', href: '/admin/gantt', icon: CalendarDaysIcon },
    { name: 'Marketing Board', href: '/admin/marketing-board', icon: ViewColumnsIcon },
    { name: 'Boilerplates', href: '/admin/boilerplate', icon: FolderIcon },
    { name: 'Code Templates', href: '/admin/code-template', icon: CodeBracketIcon },
    { name: 'Log Dev Time', href: '/admin/time', icon: ClockIcon },
    { name: 'Create Project', href: '/admin/create-project', icon: BoltIcon },
    { name: 'Schema Builder', href: '/admin/data-schema', icon: TableCellsIcon },
    { name: 'Components', href: '/admin/component', icon: CubeIcon },
    { name: 'Generate', href: '/admin/generate', icon: RocketLaunchIcon },
]
const secondaryNavigation = [{ name: 'Logout', href: '/logout', icon: ArrowDownIcon }]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function AdminLayout() {
    const location = useLocation()
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <>
            <ChatBot />
            <div className='min-h-full'>
                {/* Toggle-based Sidebar Nav */}
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as='div' className='relative z-40 lg:hidden' onClose={setSidebarOpen}>
                        <Transition.Child as={Fragment} enter='transition-opacity ease-linear duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='transition-opacity ease-linear duration-300' leaveFrom='opacity-100' leaveTo='opacity-0'>
                            <div className='fixed inset-0 bg-green-600 bg-opacity-75' />
                        </Transition.Child>

                        <div className='fixed inset-0 z-40 flex'>
                            <Transition.Child as={Fragment} enter='transition ease-in-out duration-300 transform' enterFrom='-translate-x-full' enterTo='translate-x-0' leave='transition ease-in-out duration-300 transform' leaveFrom='translate-x-0' leaveTo='-translate-x-full'>
                                <Dialog.Panel className='relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-green-700'>
                                    <Transition.Child as={Fragment} enter='ease-in-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in-out duration-300' leaveFrom='opacity-100' leaveTo='opacity-0'>
                                        <div className='absolute top-0 right-0 pt-2 -mr-12'>
                                            <button type='button' className='flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white' onClick={() => setSidebarOpen(false)}>
                                                <span className='sr-only'>Close sidebar</span>
                                                <XMarkIcon className='w-6 h-6 text-white' aria-hidden='true' />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className='flex items-center flex-shrink-0 px-4'>
                                        <img className='object-cover w-full h-12' src={logo} />
                                    </div>
                                    <nav className='flex-shrink-0 h-full mt-5 overflow-y-auto divide-y divide-green-800' aria-label='Sidebar'>
                                        <div className='px-2 space-y-1'>
                                            {navigation.map((item) => (
                                                <div key={item.name}>
                                                    {item.category && <div>{item.category}</div>}
                                                    <Link to={item.href} className={classNames(location.pathname.endsWith(item.href) ? 'bg-green-800 text-white' : 'text-green-100 hover:text-white hover:bg-green-600', 'group flex items-center px-2 py-2 text-base font-medium rounded-md')} aria-current={item.current ? 'page' : undefined}>
                                                        <item.icon className='flex-shrink-0 w-6 h-6 mr-4 text-green-200' aria-hidden='true' />
                                                        {item.name}
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='pt-6 mt-6'>
                                            <div className='px-2 space-y-1'>
                                                {secondaryNavigation.map((item) => (
                                                    <a key={item.name + '-TransitionSidebarSecondaryLink'} href={item.href} className='flex items-center px-2 py-2 text-base font-medium text-green-100 rounded-md group hover:bg-green-600 hover:text-white'>
                                                        <item.icon className='w-6 h-6 mr-4 text-green-200' aria-hidden='true' />
                                                        {item.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </nav>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div className='flex-shrink-0 w-14' aria-hidden='true'>
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Width-based Sidebar Nav */}
                <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col'>
                    <div className='flex flex-col flex-grow pt-5 pb-4 overflow-y-auto bg-green-700'>
                        <div className='flex items-center flex-shrink-0 px-4'>
                            <img className='object-cover h-24 mx-auto' src={logo} />
                        </div>

                        <nav className='flex flex-col flex-1 mt-5 overflow-y-auto divide-y divide-green-800' aria-label='Sidebar'>
                            <div className='px-2 space-y-1'>
                                {navigation.map((item) => (
                                    <div key={item.name}>
                                        {item.category && <div className='pt-5 ml-3 text-xs font-semibold text-green-500'>{item.category}</div>}
                                        <Link to={item.href} className={classNames(location.pathname.endsWith(item.href) ? 'bg-green-800 text-white' : 'text-green-100 hover:text-white hover:bg-green-600', 'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md')} aria-current={item.current ? 'page' : undefined}>
                                            <item.icon className='flex-shrink-0 w-6 h-6 mr-4 text-green-200' aria-hidden='true' />
                                            {item.name}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            <div className='pt-6 mt-6'>
                                <div className='px-2 space-y-1'>
                                    {secondaryNavigation.map((item) => (
                                        <a key={item.name + '-SidebarSecondaryLink'} href={item.href} className='flex items-center px-2 py-2 text-sm font-medium leading-6 text-green-100 rounded-md group hover:bg-green-600 hover:text-white'>
                                            <item.icon className='w-6 h-6 mr-4 text-green-200' aria-hidden='true' />
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                {/* Page Content */}
                <div className='flex flex-col flex-1 min-h-screen lg:pl-64 bg-neutral-50'>
                    <main className='flex-1'>
                        <Outlet></Outlet>
                    </main>
                </div>
            </div>
        </>
    )
}
