import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { CommandLineIcon, EnvelopeIcon, FunnelIcon, IdentificationIcon, PencilIcon, ViewColumnsIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../components/button'
import Confirm from '../components/confirm'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import SortToggle, { sortFn } from '../components/sort-toggle'
import StatusBox from '../components/status-box'
import Tabs from '../components/tabs'

import CheckboxField from '../forms/fields/checkbox-field'
import FormModal from '../forms/form-modal'

import { api, api_delete } from '../api.service'
import { PAYMENT_STATUS, PROJECT_STAGE, WORK_STATUS } from './admin-project-statuses'

const TABS = [
    { name: 'CRM', icon: IdentificationIcon },
    { name: 'Dev', icon: CommandLineIcon },
]

export default function AdminProjects() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [deleteId, setDeleteId] = useState()
    const [activeTab, setActiveTab] = useState(0)

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/project`).then((x) => setData(x))
    }, [])

    function deleteConfirm() {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/project/${deleteId}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/admin/project`)
                .then((x) => setData(x))
                .then((x) => setDeleteId(null))
        })
    }

    function updateStatus(id, status, value) {
        api(`${process.env.REACT_APP_API_URL}/admin/project`, { data: { _id: id, [status]: value } }).then((x) => api(`${process.env.REACT_APP_API_URL}/admin/project`).then((x) => setData(x)))
    }

    if (!data) return <Loading></Loading>

    return (
        <>
            <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>
            <PageHeader headline={'Projects'} actions={<Button text='Add Project' onClick={() => navigate('/admin/manage-project/new')}></Button>}></PageHeader>
            <Tabs tabs={TABS} activeIdx={activeTab} onChange={(val) => setActiveTab(val)}></Tabs>
            <div className='w-full pb-20'>
                {activeTab === 0 && <CRMList data={data.filter((x) => x.projectStage < 3 || x.projectStage > 4)} onChangeStatus={(id, status, val) => updateStatus(id, status, val)} />}
                {activeTab === 1 && <DevList data={data.filter((x) => x.projectStage === 3 || x.projectStage === 4)} onChangeStatus={(id, status, val) => updateStatus(id, status, val)} />}
            </div>
        </>
    )
}

/*
    Sub-Components
*/
function CRMList({ data, onChangeStatus }) {
    const navigate = useNavigate()
    const [sortAlg, setSortAlg] = useState({
        accessor: 'projectStage',
        isAscending: false,
    })
    const [openFilters, setOpenFilters] = useState(false)
    const [filters, setFilters] = useState([
        { text: 'Hide Declined Prospects', isApplied: true, fn: (x) => x.projectStage === -1 },
        { text: 'Hide Archived Projects', isApplied: true, fn: (x) => !!x.isArchived },
        { text: 'Hide Completed Projects', isApplied: true, fn: (x) => x.projectStage === 5 && x.workStatus === 3 && x.paymentStatus === 2 },
    ])
    const statusColumns = [
        { accessor: 'projectStage', text: 'Project', statuses: PROJECT_STAGE },
        { accessor: "workStatus", text: "Work", statuses: WORK_STATUS },
        { accessor: 'paymentStatus', text: 'Payment', statuses: PAYMENT_STATUS },
    ]

    return (
        <>
            <FormModal open={!!openFilters} setOpen={() => setOpenFilters(!openFilters)} className='w-min'>
                <div className='flex flex-col'>
                    {filters.map((flt) => (
                        <CheckboxField
                            label={flt.text}
                            value={flt.isApplied}
                            className='flex flex-row-reverse items-center justify-end'
                            onChange={(val) =>
                                setFilters(
                                    filters.map((x) => {
                                        if (x.text === flt.text) {
                                            return { ...x, isApplied: val }
                                        } else {
                                            return x
                                        }
                                    })
                                )
                            }
                        />
                    ))}
                </div>
            </FormModal>
            <div className='grid grid-cols-7 py-4 bg-white border-b border-gray-900 text-neutral-700'>
                <div className='flex justify-center col-span-2'>
                    <div onClick={() => setOpenFilters(true)} className='flex font-semibold cursor-pointer select-none'>
                        Filter{' '}
                        <div className='w-6 h-6 ml-2'>
                            <FunnelIcon></FunnelIcon>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-11 col-span-5 gap-2'>
                    {statusColumns.map((x) => (
                        <SortToggle text={x.text} accessor={x.accessor} state={sortAlg} onChange={(next) => setSortAlg(next)} className='col-span-3' defaultAscending={false}></SortToggle>
                    ))}
                </div>
            </div>

            {data
                .filter((x) => filterFn(x, filters))
                .sort((a, b) => sortFn(a, b, sortAlg))
                .map((project) => (
                    <div key={project._id} className='grid grid-cols-7 bg-white border-b hover:bg-gray-50 text-neutral-700'>
                        <div className='col-span-2'>
                            <h3 className='p-3 text-xl font-semibold text-left cursor-pointer select-none' onClick={() => navigate(`/admin/project/${project._id}`)}>
                                {project.projectName}
                            </h3>
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                            className='grid grid-cols-11 col-span-5 gap-2'
                        >
                            {statusColumns.map((col) => (
                                <StatusBox className='w-full col-span-3' statuses={col.statuses} status={project[col.accessor] ?? null} change={(val) => onChangeStatus(project._id, col.accessor, val)}></StatusBox>
                            ))}
                            <div className='col-span-1 cursor-pointer text-neutral-500 hover:bg-neutral-400 hover:text-white'>
                                {project.clientEmail !== undefined ? (
                                    <a href={`mailto:${project.clientEmail}`} className='flex justify-center w-full h-full '>
                                        <EnvelopeIcon className='w-6 mx-2 my-auto'></EnvelopeIcon>
                                    </a>
                                ) : (
                                    <div className="flex justify-center w-full h-full ">
                                        <EnvelopeIcon className='w-6 mx-2 my-auto text-neutral-200'></EnvelopeIcon>
                                    </div>
                                )}
                            </div>
                            <div className='flex justify-center col-span-1 cursor-pointer text-neutral-500 hover:bg-neutral-400 hover:text-white' onClick={() => navigate('/admin/manage-project/' + project._id)}>
                                <PencilIcon className='w-6 mx-2 my-auto'></PencilIcon>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    )
}

function DevList({ data, onChangeStatus }) {
    const navigate = useNavigate()
    const [sortAlg, setSortAlg] = useState({
        accessor: 'workStatus',
        isAscending: false,
    })
    const [openFilters, setOpenFilters] = useState(false)
    const [filters, setFilters] = useState([{ text: 'Hide Archived Projects', isApplied: true, fn: (x) => !!x.isArchived }])
    const statusColumns = [
        { accessor: 'projectStage', text: 'Project', statuses: PROJECT_STAGE },
        { accessor: 'workStatus', text: 'Work', statuses: WORK_STATUS },
        { accessor: "paymentStatus", text: "Payment", statuses: PAYMENT_STATUS }
    ]

    return (
        <>
            <FormModal open={!!openFilters} setOpen={() => setOpenFilters(!openFilters)}>
                <div className='flex flex-col'>
                    {filters.map((flt) => (
                        <CheckboxField
                            label={flt.text}
                            value={flt.isApplied}
                            className='flex flex-row-reverse items-center justify-end'
                            onChange={(val) => setFilters(filters.map((x) => x.text === flt.text ? {...x, isApplied: val} : x))}
                        />
                    ))}
                </div>
            </FormModal>
            <div className='grid grid-cols-7 py-4 bg-white border-b border-gray-900 text-neutral-700'>
                <div className='flex justify-center col-span-2'>
                    <div onClick={() => setOpenFilters(true)} className='flex font-semibold cursor-pointer select-none'>
                        Filter{' '}
                        <div className='w-6 h-6 ml-2'>
                            <FunnelIcon></FunnelIcon>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-11 col-span-5 gap-2'>
                    {statusColumns.map((x) => (
                        <SortToggle text={x.text} accessor={x.accessor} state={sortAlg} onChange={(next) => setSortAlg(next)} className='col-span-3' defaultAscending={false}></SortToggle>
                    ))}
                </div>
            </div>

            {data
                .filter((x) => filterFn(x, filters))
                .sort((a, b) => sortFn(a, b, sortAlg))
                .map((project) => (
                    <div key={project._id} className='grid grid-cols-7 bg-white border-b hover:bg-gray-50 text-neutral-700'>
                        <div className='col-span-2 p-3'>
                            <h3 className='text-xl font-semibold text-left cursor-pointer select-none' onClick={() => navigate(`/admin/project/${project._id}`)}>
                                {project.projectName}
                            </h3>
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                            className='grid grid-cols-11 col-span-5 gap-2'
                        >
                            {statusColumns.map((col) => (
                                <StatusBox className='w-full col-span-3' statuses={col.statuses} status={project[col.accessor] ?? null} change={(val) => onChangeStatus(project._id, col.accessor, val)}></StatusBox>
                            ))}
                            <div
                                className='flex justify-center, items-center col-span-1 cursor-pointer text-neutral-500 hover:bg-neutral-400 hover:text-white'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    navigate(`/admin/project/${project._id}#board`)
                                }}
                            >
                                <div className='relative w-6 h-6 mx-2 my-auto'>
                                    <ViewColumnsIcon className='absolute top-0 right-0 w-5 h-5' />
                                    <PlusCircleIcon className='absolute bottom-0 left-0 z-10 w-4 h-4 min-w-4 min-h-4' />
                                </div>
                            </div>
                            <div
                                className='flex justify-center col-span-1 cursor-pointer text-neutral-500 hover:bg-neutral-400 hover:text-white'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    navigate('/admin/manage-project/' + project._id)
                                }}
                            >
                                <PencilIcon className='w-6 mx-2 my-auto'></PencilIcon>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    )
}

/*
  Helpers
*/
function filterFn(item, filters) {
    for (const f of filters) {
        if (f.isApplied && f.fn(item)) {
            return false
        }
    }
    return true
}

/*
    Deprecated
*/

// generic project list, all statuses
// const ProjectList = ({ data, onChangeStatus }) => {
//     const navigate = useNavigate()
//     const [sortAlg, setSortAlg] = useState({
//         param: "projectStage",
//         isAscending: true
//     })
//     const [openFilters, setOpenFilters] = useState(false)
//     const [filters, setFilters] = useState({
//         hideDeclined: true,
//         hideArchived: true,
//     })

//     function sortFn(a, b, { param, isAscending }) {
//         a = a[param]
//         b = b[param]

//         if (a === undefined) return b === undefined ? 0 : 1
//         if (b === undefined) return -1

//         return (a > b ? 1 : -1) * (isAscending ? 1 : -1)
//     }

//     function filterFn(item, { hideArchived, hideDeclined }) {
//         if (hideArchived && item.isArchived) {
//             return false
//         }
//         if (hideDeclined && item.projectStage === -1) {
//             return false
//         }
//         return true
//     }

//     return (<>
//         <FormModal open={!!openFilters} setOpen={() => setOpenFilters(!openFilters)}>
//             <CheckboxField label="Hide Declined Prospects" value={filters.hideDeclined} onChange={(val) => setFilters({ ...filters, hideDeclined: val })}></CheckboxField>
//             <CheckboxField label="Hide Archived Projects" value={filters.hideArchived} onChange={(val) => setFilters({ ...filters, hideArchived: val })}></CheckboxField>
//         </FormModal>
//         <div className="grid grid-cols-6 py-4 bg-white border-b border-gray-900 text-neutral-700">
//             <div className="flex justify-center col-span-2">
//                 <div onClick={() => setOpenFilters(true)} className="flex cursor-pointer select-none">
//                     Filter{' '}<div className="w-6 h-6 ml-2"><FunnelIcon></FunnelIcon></div>
//                 </div>
//             </div>
//             <div className="grid grid-cols-10 col-span-4 gap-4">
//                 {[{ value: "projectStage", text: "Project" }, { value: "workStatus", text: "Work" }, { value: "paymentStatus", text: "Payment" },].map(x => (
//                     <div key={x.value} className="flex justify-center w-full col-span-3 mx-2 text-center cursor-pointer select-none" onClick={() => { setSortAlg({ param: x.value, isAscending: sortAlg.param === x.value ? !sortAlg.isAscending : true }) }}>{x.text}{' '}<div className={`w-6 h-6 ml-2 ${sortAlg.param === x.value ? "text-green-600" : "text-gray-300"}`}><ArrowsUpDownIcon></ArrowsUpDownIcon></div></div>
//                 ))}
//             </div>
//         </div>

//         {data.filter(x => filterFn(x, filters)).sort((a, b) => sortFn(a, b, sortAlg)).map(x => (
//             <div key={x._id} onClick={() => navigate(`/admin/project/${x._id}`)} className="grid grid-cols-6 bg-white border-b cursor-pointer select-none hover:bg-gray-50 text-neutral-700">
//                 <div className="col-span-2 p-3">
//                     <h3 className="text-xl font-semibold text-left">{x.projectName}</h3>
//                 </div>
//                 <div onClick={(e) => { e.stopPropagation(); }} className="grid grid-cols-10 col-span-4 gap-4">
//                     <StatusBox className="w-full col-span-3" statuses={PROJECT_STAGE} status={x.projectStage ?? null} change={(status) => onChangeStatus(x._id, "projectStage", status)}></StatusBox>
//                     <StatusBox className="w-full col-span-3" statuses={WORK_STATUS} status={x.workStatus ?? null} change={(status) => onChangeStatus(x._id, "workStatus", status)}></StatusBox>
//                     <StatusBox className="w-full col-span-3" statuses={PAYMENT_STATUS} status={x.paymentStatus ?? null} change={(status) => onChangeStatus(x._id, "paymentStatus", status)}></StatusBox>
//                     <div className="flex justify-center col-span-1 cursor-pointer text-neutral-500 hover:bg-neutral-400 hover:text-white" onClick={(e) => { e.stopPropagation(); navigate('/admin/manage-project/' + x._id) }}>
//                         <PencilIcon className="w-6 mx-2 my-auto"></PencilIcon>
//                     </div>
//                 </div>
//             </div>
//         ))}
//     </>)
// }
