import { Link } from 'react-router-dom'

const buttonType = {
    primary: 'bg-green-600 hover:bg-green-700 focus:ring-green-500',
    secondary: 'bg-sky-600 hover:bg-sky-700 focus:ring-sky-500',
    warning: 'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500',
    danger: 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
}

export default function Button(props) {
    const className = `${props.disabled ? 'opacity-20' : ''} cursor-pointer justify-center inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 ${buttonType[props?.type ?? 'primary']} ${props.className}`

    if (props.link) {
        return (
            <Link className={className} to={props.link}>
                {props.text}
            </Link>
        )
    }
    return (
        <div className={className} onClick={() => (props.disabled ? {} : props.onClick())}>
            {props.text}
        </div>
    )
}

<Button text="Button Text" onClick={() => { }}></Button>