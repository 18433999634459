import { PaperClipIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'

import Button from '../components/button'

import NumberField from '../forms/fields/number-field'

export default function TimeTrackerForm({ userName, onSubmit }) {
    const [state, setState] = useState({
        date: new Date(),
        hours: 0,
        text: '',
    })
    const [feedback, setFeedback] = useState(null)

    function checkSubmission() {
        let submission = { ...state, text: state.text.trim() }
        if (submission.hours <= 0) {
            setFeedback('Please set hours > 0')
            return
        }
        if (!submission.text) {
            setFeedback('Please set a description')
            return
        }
        onSubmit(submission)
        setState({ ...state, date: new Date(), hours: 0, text: '' })
    }

    return (
        <>
            <div className='flex flex-row justify-between'>
                <h2 className='text-xl font-bold text-gray-900'>Log Time</h2>
            </div>

            {/* Hours Input */}
            <div className='flex flex-row items-end'>
                <NumberField
                    label='Work Hours'
                    min={0}
                    step={0.5}
                    value={state.hours}
                    onChange={(val) => {
                        setState({ ...state, hours: val })
                        setFeedback(null)
                    }}
                ></NumberField>
                {[1, 4, 8].map((x) => (
                    <Button key={x} type='secondary' onClick={() => setState({ ...state, hours: x })} className='ml-4 h-min' text={`${x}hr${x !== 1 ? 's' : ''}`}></Button>
                ))}
            </div>

            {/* Task Description */}
            <div className='pb-12 mt-4 overflow-hidden rounded-lg ring-inset ring-1 ring-gray-900/20 focus-within:ring-2 focus-within:ring-green-600'>
                <label htmlFor='description' className='sr-only'>
                    Add a description
                </label>
                <textarea
                    rows={2}
                    name='description'
                    id='description'
                    className='block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                    placeholder='Add a description...'
                    value={state.text}
                    onChange={({ target: { value } }) => {
                        setState({ ...state, text: value })
                        setFeedback(null)
                    }}
                />
            </div>

            <div className='inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2'>
                {/* Attachments */}
                <div className='flex items-center space-x-5'>
                    <button type='button' className='-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500'>
                        <PaperClipIcon className='w-5 h-5' aria-hidden='true' />
                        <span className='sr-only'>Attach a file</span>
                    </button>
                </div>

                {/* Submission */}
                <div className='flex flex-row '>
                    <p className='px-2.5 py-1.5 text-sm font-semibold text-orange-400'>{feedback}</p>
                    <Button text='Submit' onClick={() => checkSubmission()} className='text-lg font-bold'></Button>
                </div>
            </div>
        </>
    )
}
