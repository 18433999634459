import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { api } from '../api.service'
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import Button from '../components/button'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'

import FileField from '../forms/fields/file-field'

export default function Contract() {
    const [data, setData] = useState(null)
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [tab, setTab] = useState(0)

    const [open, setOpen] = useState(false)
    const params = useParams()

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
        api(`${process.env.REACT_APP_API_URL}/project`).then((x) => {
            setData(x)
        })
    }, [])
    const submit = (val) => {
        api(`${process.env.REACT_APP_API_URL}/project/contract`, { data: { contracts: data.contracts.map((x, i) => (i === tab ? { ...x, signed: val } : x)) } }).then((x) => window.location.reload())
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
    }

    if (!data) return <Loading></Loading>

    return (
        <>
            <PageHeader headline={'Contracts'}></PageHeader>

            <div className='flex flex-col max-w-6xl p-10 mx-auto mt-10 bg-white shadow text-neutral-800'>
                <Modal
                    open={open}
                    setOpen={setOpen}
                    uploadSuccess={(val) => {
                        setOpen(false)
                        submit(val)
                    }}
                ></Modal>

                <div className='text-3xl font-bold text-center'>Contracts</div>

                <div className='mt-10 font-medium text-center text-neutral-700'>View, download, and upload contracts. Once all contracts are signed we will begin the development stage of your MVP</div>

                {!data.contracts?.length && <div className='mt-10 font-semibold text-center text-neutral-800'>We're still preparing your contract. We will let you know as soon as your contract is ready to sign.</div>}

                {data.contracts?.length > 0 && (
                    <div className='mt-10'>
                        <div>
                            <div className='sm:hidden'>
                                <label htmlFor='tabs' className='sr-only'>
                                    Select a tab
                                </label>
                                <select id='tabs' name='tabs' className='block w-full border-gray-300 rounded-md focus:border-green-500 focus:ring-green-500' defaultValue={data.contracts[tab].name}>
                                    {data.contracts?.map((tab) => (
                                        <option key={tab.name}>{tab.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='hidden sm:block'>
                                <div className='border-b border-gray-200'>
                                    <nav className='flex -mb-px' aria-label='Tabs'>
                                        {data.contracts?.map((contract, i) => (
                                            <span
                                                onClick={() => setTab(i)}
                                                key={contract.name}
                                                className={`
                    ${tab === i ? 'border-green-500 text-green-600 ' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 '}
                    'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
                  `}
                                            >
                                                {contract.name} {contract.signed && <CheckIcon className='inline w-4 ml-2 text-green-400'></CheckIcon>}
                                            </span>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {!data.contracts[tab]?.file && !data.contracts[tab]?.signed && <div className='font-semibold text-center text-neutral-800'>We're still preparing your contract. We will let you know as soon as your contract is ready to sign.</div>}
                        {data.contracts[tab]?.file && !data.contracts[tab]?.signed && (
                            <div>
                                <div className='flex justify-between my-5'>
                                    <a href={`${process.env.REACT_APP_FILE_URL}/${data.contracts[tab].file}`} download='Verticode Contract.pdf' target='_blank'>
                                        Download Contract
                                    </a>
                                    <Button text='Upload Signed Contract' onClick={() => setOpen(true)}></Button>
                                </div>
                                <div className='text-xl font-semibold text-center text-neutral-800'>Unsigned Contract</div>

                                <div className='p-5 bg-neutral-300 inner-shadow'>
                                    <Document onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess} file={{ url: `${process.env.REACT_APP_FILE_URL}/${data.contracts[tab].file}` }}>
                                        {Array.from(new Array(numPages), (el, index) => (
                                            <Page renderTextLayer={false} renderAnnotationLayer={false} width={900} key={`page_${index + 1}`} pageNumber={index + 1} />
                                        ))}
                                    </Document>
                                </div>
                            </div>
                        )}

                        {data.contracts[tab]?.signed && (
                            <div>
                                <div className='flex justify-between my-5'>
                                    <a href={`${process.env.REACT_APP_FILE_URL}/${data.contracts[tab].signed}`} download='Verticode Signed Contract.pdf' target='_blank'>
                                        Download Contract
                                    </a>
                                    <Button text='Re-Upload Signed Contract' onClick={() => setOpen(true)}></Button>
                                </div>
                                <div className='text-xl font-semibold text-center text-neutral-800'>Signed Contract</div>

                                <div className='p-5 bg-neutral-300 inner-shadow'>
                                    <Document onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess} file={{ url: `${process.env.REACT_APP_FILE_URL}/${data.contracts[tab].signed}` }}>
                                        {Array.from(new Array(numPages), (el, index) => (
                                            <Page renderTextLayer={false} renderAnnotationLayer={false} width={900} key={`page_${index + 1}`} pageNumber={index + 1} />
                                        ))}
                                    </Document>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

function Modal({ open, setOpen, uploadSuccess }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-10' onClose={setOpen}>
                <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
                    <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' enterTo='opacity-100 translate-y-0 sm:scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 translate-y-0 sm:scale-100' leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                            <Dialog.Panel className='relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                                <div>
                                    <div className='mt-3 text-center sm:mt-5'>
                                        <Dialog.Title as='h3' className='text-base font-semibold leading-6 text-gray-900'>
                                            Upload Signed Contract
                                        </Dialog.Title>
                                        <div className='mt-2'>
                                            <FileField endpoint='project' label='Signed Contract' onChange={(val) => uploadSuccess(val)}></FileField>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
