import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import draftToHtml from 'draftjs-to-html'

import Button from '../components/button'
import Confirm from '../components/confirm'
import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

import { api, api_delete } from '../api.service'
import logo from '../logo.png'

export default function Domain() {
    const [data, setData] = useState(null)

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/project`).then((x) => setData(x))
    }, [])

    if (!data) return <Loading></Loading>
    const rawContentState = data.domainInstructions ? (data.domainInstructions.entityMap ? data.domainInstructions : { entityMap: {}, ...data.domainInstructions }) : { blocks: [], entityMap: {} }
    const markup = draftToHtml(rawContentState)

    return (
        <>
            <PageHeader headline={'Connect a Custom Domain'}></PageHeader>

            <div className='flex flex-col max-w-6xl p-10 mx-auto mt-10 font-medium bg-white shadow text-neutral-800'>
                <div className='text-3xl font-bold text-center'>Connect a Custom Domain</div>

                <div className='mt-10 font-medium text-center text-neutral-700'>You can connect your MVP to a custom domain or subdomain to keep your branding consistent.</div>

                <div className='mt-10 text-center text-neutral-700'>To connect a custom domain to your MVP you will need to add some DNS records. You can update your DNS records through the website you bought your domain from.</div>

                <div className='my-3 font-normal text-center text-neutral-700'>
                    If you bought your domain from Google{' '}
                    <a className='text-green-500 underline' target='_blank' href='https://support.google.com/domains/answer/3290309?hl=en-GB'>
                        click here for instructions
                    </a>
                </div>
                <div className='my-3 font-normal text-center text-neutral-700'>
                    If you bought your domain from GoDaddy{' '}
                    <a className='text-green-500 underline' target='_blank' href='https://uk.godaddy.com/help/edit-an-a-record-19239'>
                        click here for instructions
                    </a>
                </div>
                <div className='mt-10 text-sm text-center text-neutral-600'>Required DNS records for your application</div>
                <div className='inline p-5 mx-auto mb-10 text-left border text-neutral-800'>
                    <div dangerouslySetInnerHTML={{ __html: markup }}></div>
                </div>
                <div className='mt-10 text-center text-neutral-700'>If you need support setting up your custom domain please email us at hello@verticode.co.uk</div>
            </div>
        </>
    )
}
