import { useState } from 'react'

import TextField from './text-field'

export default function EditableCheckboxField({ defaultLabelText, checked, onCheckboxChange, onLabelChange, className, checkboxClass, onBlur, labelClass }) {
    const [isEditingText, setIsEditingText] = useState(false)
    const [labelText, setLabelText] = useState(defaultLabelText)

    return (
        <div className={`flex place-items-center ${className}`}>
            <div>
                <input type='checkbox' checked={checked} onBlur={onBlur} onChange={(e) => onCheckboxChange(e.target.checked)} className={`w-7 h-7 block rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 ${checkboxClass}`} />
            </div>
            {isEditingText ? (
                <div
                    className={`block text-sm font-medium leading-6 text-gray-900 ml-3 ${labelClass}`}
                    onBlur={() => {
                        setIsEditingText(false)
                    }}
                >
                    <TextField
                        value={labelText}
                        onChange={(val) => {
                            setLabelText(val)
                        }}
                        onBlur={() => {
                            onLabelChange(labelText)
                        }}
                    />
                </div>
            ) : (
                <div
                    className={`block text-sm font-medium leading-6 text-gray-900 ml-3 ${labelClass}`}
                    onClick={() => {
                        setIsEditingText(true)
                    }}
                >
                    {labelText}
                </div>
            )}
        </div>
    )
}
