import React, { useEffect, useState } from 'react'
import { api } from '../api.service'
import CodeEditor from '../components/code-editor'
import SimpleLoader from '../components/SimpleLoader'

export default function Generate() {
    const [view, setView] = useState('project')
    const [models, setModels] = useState([{ modelName: '', fields: '' }])
    const [outline, setOutline] = useState('')
    const [project, setProject] = useState({ 
        name: '', 
        forms: [], 
        models: [],
        pages: [],
        routes: '',
     })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    
    useEffect(() => {
        if (project.name.length > 0) {
            setError('')
        }
    }, [project])

    const handleSuggest = (outline) => {
        setLoading(true)
        if (!outline) {
            setLoading(false)
            return setError('Please enter a project outline.')
        }
        api(`${process.env.REACT_APP_API_URL}/generate/suggest`, { outline }).then(x => {
            try {
                const y = JSON.parse(x.text)
                if (y.error) {
                    setError(y.error)
                    setOutline('')
                    return setLoading(false)
                }
                setModels(y.data)
                setView('model')
                setLoading(false)
            } catch(e) {
                console.error(e)
                setLoading(false)
            }
        })
    }

    const createProject = () => {
        setLoading(true)
        if (!project.name) {
            setLoading(false)
            return setError('Please name the project.')
        }
        setError('')
        fetch(`${process.env.REACT_APP_API_URL}/generate/boilerplate`, {
            method: 'POST',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(project),
        })
            .then((transfer) => transfer.blob())
            .then((bytes) => {
                let elm = document.createElement('a')
                elm.href = URL.createObjectURL(bytes)
                elm.setAttribute('download', `${project.name}.zip`)
                elm.click()
                setLoading(false)
                return true
            })
            .catch((error) => {
                console.error(error)
            })
    }
    
    const handleRemoveModelFromProject = (model) => {
        setProject({ ...project, models: [ ...project.models.filter(p => p.name !== model.model.split(' ')[model.model.split(' ').length - 1]) ]})
    }

    return (
        <>
            <div className='flex justify-center py-4 gap-x-6'>
                <span className={`cursor-pointer ${view === 'project' ? 'font-bold underline' : ''}`} onClick={() => {setError(''); setView('project')}}>Project</span>
                <span className={`cursor-pointer ${view === 'model' ? 'font-bold underline' : ''}`} onClick={() => {setError('');setView('model')}}>Model</span>
                <span className={`cursor-pointer ${view === 'form' ? 'font-bold underline' : ''}`} onClick={() => {setError('');setView('form')}}>Form</span>
                <span className={`cursor-pointer ${view === 'pages' ? 'font-bold underline' : ''}`} onClick={() => {setError('');setView('pages')}}>Pages</span>
                <span className={`cursor-pointer ${view === 'routes' ? 'font-bold underline' : ''}`} onClick={() => {setError('');setView('routes')}}>Routes</span>
            </div>
            {error && <p className='px-10 py-2 mx-auto my-4 text-center text-red-500 border border-red-500 rounded bg-red-100/50 w-fit'>{error}</p>}

            <div className='relative max-w-4xl p-10 mx-auto bg-white rounded shadow'>
                {loading && <div className='absolute top-0 z-50 flex items-center justify-center right-3'>
                    <SimpleLoader />
                </div>}
                {view === 'project' && 
                    <>
                        <div>
                            <input className='p-2 text-base border border-gray-300 rounded' placeholder='Set Project name' value={project.name} onChange={(e) => setProject({ ...project, name: e.target.value})} />
                        </div>
                        <GenerateSuggestions outline={outline} setOutline={setOutline} handleSuggest={handleSuggest} loading={loading} />
                        <Project project={project} setProject={setProject} createProject={createProject} loading={loading} handleRemoveModelFromProject={handleRemoveModelFromProject} />
                    </>
                }
                {view === 'model' && <GenerateModel loading={loading} setLoading={setLoading} project={project} setProject={setProject} models={models} setModels={setModels} />}
                {view === 'form' && <GenerateForm loading={loading} setLoading={setLoading} project={project} setProject={setProject} handleRemoveModelFromProject={handleRemoveModelFromProject} />}
                {view === 'pages' && <GeneratePages loading={loading} setLoading={setLoading} project={project} setProject={setProject} />}
                {view === 'routes' && <GenerateRoutes loading={loading} setLoading={setLoading} project={project} setProject={setProject} />}
            </div>
        </>
    )
}

const Project = ({ 
    project, 
    setProject, 
    createProject, 
    loading, 
    handleRemoveModelFromProject 
}) => {

    const handleRemoveFormFromProject = (name) => {
        setProject({ ...project, forms: [ ...project.forms.filter(f => f.name !== name) ] })
    }
    const handleRemovePageFromProject = (page) => {
        setProject({ ...project, pages: [ ...project.pages.filter(f => f.page !== page) ] })
    }

    return (
        <div className='max-w-xl mx-auto'>
            <div className='max-w-xl p-4 mt-4 rounded shadow'>
                <h2 className='text-xl font-bold'>Models:</h2>
                {project.models.length === 0 && <span>No models added yet.</span>}
                <div className='flex flex-col px-6 my-4 gap-y-2'>
                    {project.models.length > 0 && project.models.map((m,i) => (
                        <div key={m.name + i} className='flex justify-between'>
                            <span className='text-lg '>{m.name}</span>
                            <button className='text-red-500' onClick={() => handleRemoveModelFromProject(m)}>Remove</button>
                        </div>
                    ))}
                </div>
            </div>
            <div className='max-w-xl p-4 mt-4 rounded shadow'>
                <h2 className='text-xl font-bold'>Forms:</h2>
                {project.forms.length === 0 && <span>No forms added yet.</span>}
                <div className='flex flex-col px-6 my-4 gap-y-2'>
                    {project.forms.length > 0 && project.forms.map((f,i) => (
                        <div key={f.name + i} className='flex justify-between'>
                            <span className='text-lg '>{f.name}</span>
                            <button className='text-red-500' onClick={() => handleRemoveFormFromProject(f.name)}>Remove</button>
                        </div>
                    ))}
                </div>
            </div>
            <div className='max-w-xl p-4 mt-4 rounded shadow'>
                <h2 className='text-xl font-bold'>Pages:</h2>
                {project.pages.length === 0 && <span>No pages added yet.</span>}
                <div className='flex flex-col px-6 my-4 gap-y-2'>
                    {project.pages.length > 0 && project.pages.map((p,i) => (
                        <div key={p.page + i} className='flex justify-between'>
                            <span className='text-lg '>{p.page}</span>
                            <button className='text-red-500' onClick={() => handleRemovePageFromProject(p.page)}>Remove</button>
                        </div>
                    ))}
                </div>
            </div>
            <button disabled={!!loading} className={`w-full py-2 mt-4 text-white rounded ${loading ? 'bg-gray-500' : 'bg-green-700'}`} onClick={createProject}>Generate Boilerplate</button>
        </div>
    )
}

const GenerateSuggestions = ({ 
    outline, 
    setOutline, 
    handleSuggest, 
    loading 
}) => {
    return (
        <div className='my-4'>
            <h2 className='text-xl font-bold'>Project Outline</h2>
            <textarea value={outline} onChange={(e) => setOutline(e.target.value)} placeholder='Use the project outline to auto-generate models. You can edit in the next page before they are added to the project.' rows={4} className='w-full border border-gray-300 rounded' />
            <button onClick={() => handleSuggest(outline)} className={`px-3 py-1 text-white rounded ${loading ? 'bg-gray-500' : 'bg-green-700'}`}>Submit</button>
        </div>
    )
}

const GenerateModel = ({ 
    project, 
    setProject, 
    models, 
    setModels,
    loading,
    setLoading
}) => {
    const [response, setResponse] = useState()
    const [error, setError] = useState()

    const submit = () => {
        setError('')
        if ((models.length === 1 && !models[0].modelName) || models.length === 0) {
            return setError('Please add a model')
        }
        setLoading(true)
        api(`${process.env.REACT_APP_API_URL}/generate/model`, { models }).then(x => {
            try {const y = JSON.parse(x.model)
            setResponse(y)
            setLoading(false)
            } catch (e) {
                console.error(e)
                setLoading(false)
            }
        })
    }

    const handleAddToProject = (model) => {
        setProject({ ...project, models: [ ...project.models, { name: model.model.split(' ')[model.model.split(' ').length - 1], model: model.model } ]})
        
        if (models.length > 1) {
            setModels([ ...models.filter(m => m.modelName !== model.model.split(' ')[model.model.split(' ').length - 1]) ])
            setResponse([ ...response.filter(r => r.model.split(' ')[model.model.split(' ').length - 1] !== model.model.split(' ')[model.model.split(' ').length - 1])])
        } else {
            setModels([{ modelName: '', fields: '' }])
            setResponse()
        }
    }

    return (
        <>
            <div className='grid grid-cols-2 gap-2'>
                <h1 className='col-span-2 text-2xl'>Model Generator</h1>
                <div className='grid gap-2 mt-4'>
                    {models.map((m, i) => (
                        <div key={i} className='relative flex flex-col w-full p-6 rounded shadow gap-y-2'>
                            <span className='absolute font-bold cursor-pointer top-1 right-3' onClick={() => setModels([ ...models.slice(0, i), ...models.slice(i + 1) ])}>X</span>
                            <input className='p-2 border border-gray-100 rounded' placeholder='Model Name' value={m.modelName} onChange={(e) => setModels([ ...models.slice(0, i), { ...m, modelName: e.target.value }, ...models.slice(i + 1) ])} />
                            <textarea className='border border-gray-100 rounded' placeholder='Fields (e.g., name description author text modelRef...)' value={m.fields} onChange={(e) => setModels([ ...models.slice(0, i), { ...m, fields: e.target.value }, ...models.slice(i + 1) ])} />
                        </div>
                    ))}
                </div>

                {response &&
                    <div className='grid gap-2'> 
                        {response.map((r, i) => (
                            <div key={`model${i}`}>
                                <CodeEditor height={350} options={{ readOnly: true, minimap: { enabled: false } }} value={r.model} defaultLanguage='typescript' />
                                <button onClick={() => handleAddToProject(r)}>Add to project</button>
                            </div>
                        ))}
                    </div>
                }
                <div className='flex justify-between w-full col-span-2'>
                    <button className='px-3 py-2 text-white bg-green-700 rounded' onClick={() => setModels([ ...models, { modelName: '', fields: '' } ])}>Add</button>
                    <div className='flex items-center gap-x-4'>
                        {error && <span className='text-red-500'>{error}</span>}
                        <button disabled={!!loading} className={`px-3 py-2 text-white rounded ${loading ? 'bg-gray-500' : 'bg-green-700'}`} onClick={submit}>Generate Models</button>
                    </div>
                </div>
            </div>
        </>
    )
}

const GenerateForm = ({ 
    project, 
    setProject, 
    handleRemoveModelFromProject,
    loading,
    setLoading
}) => {
    const [model, setModel] = useState('')
    const [response, setResponse] = useState()
    const [error, setError] = useState('')

    const submit = () => {
        setError('')
        if (!model) {
            return setError('Please provide a model.')
        }
        setLoading(true)
        api(`${process.env.REACT_APP_API_URL}/generate/form`, { model }).then(x => {
            setResponse(x.form.replace(/^```|```$/g, '').trim())
            setLoading(false)
        })   
    }

    const handleAddToProject = (form) => {
        setProject({ ...project, forms: [ ...project.forms, { name: form.split('export default function')[1].split('()')[0].trim(), form: form } ]})
        setModel('')
        setResponse()
    }

    const handleRemoveFromProject = (name) => {
        setProject({ ...project, forms: [ ...project.forms.filter(f => f.name !== name) ] })
    }

    return (
        <div className='w-full mx-auto'>
            <div className='grid grid-cols-2 gap-2'>
                {project.forms.length > 0 && <div className='grid grid-cols-1 col-span-2 p-4 mx-auto rounded shadow gap-y-4'>
                    {project.forms.map(f => (
                        <div key={f.name} className='flex justify-between gap-x-32'>
                            <h2 className='inline font-bold'>{f.name}</h2>
                            <button className='inline text-sm text-red-500' onClick={() => handleRemoveFromProject(f.name)}>Remove</button>
                        </div>
                    ))}
                </div>}

                <div className='col-span-2 mb-4 border-b border-gray-100/50'>
                    <h1 className='text-2xl'>Form Generator</h1>
                </div>
                <textarea className='w-full border border-gray-100 rounded' rows={6} placeholder='Copy model interface/type here' value={model} onChange={(e) => setModel(e.target.value)} />
                {!response && <h3 className='my-auto text-center'>Generate form to see code</h3>}
                {response &&
                    <CodeEditor height={400} options={{ readOnly: true, minimap: { enabled: false } }} value={response} defaultLanguage='typescript' />
                }
            </div>
            
            <div className='flex justify-between w-full mx-auto my-4'>
                <div className='flex items-center gap-x-4'>
                    <button disabled={!!loading} className={`px-3 py-2 text-white rounded ${loading ? 'bg-gray-500' : 'bg-green-700'}`} onClick={submit}>Generate Form</button>
                    {error && <span className='text-red-500'>{error}</span>}
                </div>
                {response && <button className={`px-3 py-2 text-white rounded ${loading ? 'bg-gray-500' : 'bg-green-700'}`} onClick={() => handleAddToProject(response)}>Add to Project</button>}
            </div>

            <h2 className='col-span-2 my-6 text-xl font-bold border-b border-gray-100/50'>View Models in Project</h2>
            <div className='grid grid-cols-2 col-span-2 gap-2'>
                {project.models.map(r => (
                    <div key={r.name} className='p-4 rounded shadow'>
                        <div className='flex justify-between'>
                            <h2 className=''>{r.name} Model:</h2>
                            <button className='text-sm text-red-500' onClick={() => handleRemoveModelFromProject(r)}>Remove</button>
                        </div>
                        <CodeEditor height={350} options={{ readOnly: true, minimap: { enabled: false } }} value={r.model} defaultLanguage='typescript' />
                    </div>
                ))}
            </div>
        </div>
    )
}

const GeneratePages = ({ project, setProject, loading, setLoading }) => {
    const [text, setText] = useState('')
    const [response, setResponse] = useState([])
    const [error, setError] = useState('')
    
    const handleSubmit = () => {
        setLoading(true)
        if(!text) {
            setLoading(false)
            return setError('Please provide a prompt.')
        }

        api(`${process.env.REACT_APP_API_URL}/generate/pages`, { text }).then(x => {
            try {
                setError('')
                setLoading(false)
                if (JSON.parse(x.text).error) {
                    return setError(JSON.parse(x.text).error)
                }
                setResponse(JSON.parse(x.text).data)
            } catch(e) {
                setError(e)
                setLoading(false)
                console.error(e)
            }
        })
        }
        const handleAddToProject = () => {
            setProject({ ...project, pages: response.map(p => ({ page: p.pageName, component: p.component })) })
        }
        
    return (
        <>  
            <div className='flex flex-col items-start w-full gap-y-4'>
                <h1 className='text-2xl'>Page Suggestor</h1>
                <textarea placeholder='Insert project description here...' className='w-full border border-gray-100 rounded' value={text} onChange={(e) => setText(e.target.value)} />
                <div className='flex items-center my-4 gap-x-4'>
                    <button disabled={!!loading} className={`w-fit px-3 py-2 text-white rounded ${loading ? 'bg-gray-500' : 'bg-green-700'}`} onClick={handleSubmit}>Get Suggestions</button>
                    {error && <span className='text-red-500'>{error}</span>}
                </div>
            </div>

            {response.length > 0 && 
                <>
                    <div className='grid grid-cols-2'>
                        <h3 className='text-xl font-semibold underline'>Page</h3>
                        <h3 className='text-xl font-semibold underline'>Route</h3>
                        {response.map((p, i) => (
                            <React.Fragment key={p+i}>
                                <p key={p+i} className='cursor-pointer hover:line-through' onClick={() => setResponse(response.filter(s => s.pageName !== p.pageName))}>{p.pageName}</p>
                                <p>{p.url}</p>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className='grid grid-cols-2 gap-4'>
                        {response && response.map((p, i) => (
                            <CodeEditor key={i} height={200} options={{ readOnly: true, minimap: { enabled: false } }} value={p.component || ''} defaultLanguage='typescript' />
                        ))}
                    </div>
                    {response && response.length > 0 && <button onClick={handleAddToProject} className='px-3 py-2 mt-4 text-white bg-green-700 rounded w-fit'>Add to project</button>}
                </>
            }
        </>
    )
}

const GenerateRoutes = ({ project, setProject, loading, setLoading }) => {
    const [text, setText] = useState('')
    const [response, setResponse] = useState()
    const [error, setError] = useState('')
    
    const handleSubmit = () => {
        setLoading(true)
        if (!text) {
            setLoading(false)
            return setError('Please input model names.')
        }
        setError('')
        api(`${process.env.REACT_APP_API_URL}/generate/routes`, { text }).then(x => {
            try {
                setResponse(JSON.parse(x.text).data)
                setLoading(false)
            } catch(e) {
                setLoading(false)
                console.error(e)
            }
        })
    }

    const handleAddToProject = () => {
        setProject({ ...project, routes: response })
    }

    return (
        <>
            <div className='flex flex-col justify-start'>
                <h1 className='mb-4 text-2xl'>Route Generator</h1>
                <textarea
                    placeholder='Insert Model names here to generate get, get all, update and delete routes.'
                    className='w-full border border-gray-100 rounded' 
                    value={text} 
                    onChange={(e) => setText(e.target.value)} 
                />
                <div className='flex items-center my-4 gap-x-4'>
                    <button disabled={!!loading} className={`w-fit px-3 py-2 text-white rounded ${loading ? 'bg-gray-500' : 'bg-green-700'}`} onClick={handleSubmit}>Submit</button>
                    {error && <span className='text-red-500'>{error}</span>}
                </div>

                {response && 
                    <>
                        <div>
                            <CodeEditor height={700} options={{ readOnly: true, minimap: { enabled: false } }} value={response || ''} defaultLanguage='typescript' />
                        </div>
                        <button onClick={handleAddToProject}>Add to project</button>
                    </>
                }
            </div>
        </>
    )
}