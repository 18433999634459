import { useNavigate, useParams } from 'react-router-dom'

import CodeEditor from '../components/code-editor'
import PageHeader from '../components/page-header'

import TextField from './fields/text-field'
import FormWrapper from './form-wrapper'

export default function CodeTemplateForm(props) {
    const params = useParams()
    let id = params.id ?? props.id
    const navigate = useNavigate()
    return (
        <div>
            <PageHeader headline='Manage Code Template'></PageHeader>
            <FormWrapper url='admin/code-template' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <TextField label='Title' value={values.title} onChange={(val) => setValues({ ...values, title: val })}></TextField>
                        <TextField label='Filename' value={values.fileName} onChange={(val) => setValues({ ...values, fileName: val })}></TextField>
                        <div className='h-96'>
                            <CodeEditor value={values.code} onChange={(val) => setValues({ ...values, code: val })}></CodeEditor>
                        </div>
                    </>
                )}
            </FormWrapper>
        </div>
    )
}
