import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../components/button'
import Confirm from '../components/confirm'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

import { api, api_delete } from '../api.service'

export default function AdminCodeTemplate() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [deleteId, setDeleteId] = useState()

    const deleteConfirm = () => {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/code-template/${deleteId}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/admin/code-template`)
                .then((x) => setData(x))
                .then((x) => setDeleteId(null))
        })
    }

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/code-template`).then((x) => setData(x))
    }, [])

    if (!data) return <Loading></Loading>

    return (
        <>
            <Confirm open={!!deleteId} onCancel={() => setDeleteId(false)} onConfirm={() => deleteConfirm()}></Confirm>

            <PageHeader headline={'Code Templates'} actions={<Button text='Add Code Template' onClick={() => navigate('/admin/manage-code-template/new')}></Button>}></PageHeader>
            <div className='flex'>
                <div className='w-full max-w-6xl pb-20 mx-auto my-10'>
                    <div>
                        <Table
                            data={data.map((x) => {
                                return {
                                    ...x,
                                    title: (
                                        <div className='w-full'>
                                            {x.title}
                                            <span className='ml-4 text-gray-400'>{x.fileName}</span>
                                        </div>
                                    ),
                                    edit: (
                                        <span className='underline cursor-pointer' onClick={() => navigate(`/admin/manage-code-template/${x._id}`)}>
                                            Edit
                                        </span>
                                    ),
                                    deleteCell: (
                                        <span className='underline cursor-pointer' onClick={() => setDeleteId(x._id)}>
                                            Delete
                                        </span>
                                    ),
                                }
                            })}
                            columns={[
                                {
                                    Header: 'Title',
                                    accessor: 'title',
                                },
                                {
                                    Header: 'Edit',
                                    accessor: 'edit',
                                },
                                {
                                    Header: 'Delete',
                                    accessor: 'deleteCell',
                                },
                            ]}
                        ></Table>
                    </div>
                </div>
            </div>
        </>
    )
}
