import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useWebSocket from 'react-use-websocket'
import moment from 'moment'

import TimeTrackerForm from '../forms/time-tracker-form'

import { api } from '../api.service'
import Card from './card'
import ContentWidthWrapper from './content-width-wrapper'
import Loading from './loading'

function totalsByUser(timeChunks) {
    const totals = {}
    for (const chunk of timeChunks) {
        totals[chunk.assignedTo?.name] = totals[chunk.assignedTo?.name] || 0
        totals[chunk.assignedTo?.name] += +chunk.hours
    }
    return totals
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DevTimeTracker(props) {
    const params = useParams()
    const [timeChunks, setChunks] = useState(null)

    useEffect(() => {
        loadData()
    }, [params.id, props.id])

    const loadData = () => {
        api(`${process.env.REACT_APP_API_URL}/admin/times/${params?.id ?? props.id}`).then((x) => setChunks(x))
    }

    const { sendMessage, lastMessage, readyState } = useWebSocket(process.env.REACT_APP_WS_URL)
    useEffect(() => {
        if (lastMessage?.data?.includes('time')) {
            loadData()
        }
    }, [lastMessage])

    function createItem(formValues) {
        let newChunk = { project: params?.id ?? props.id, ...formValues }
        api(`${process.env.REACT_APP_API_URL}/admin/time`, { data: newChunk })
    }

    if (!timeChunks) {
        return <Loading></Loading>
    }

    console.log(timeChunks)

    return (
        <ContentWidthWrapper>
            {/* Total & Total by Developer */}
            <Card className='bg-white'>
                <div className='flex flex-wrap divide-x divide-gray-200'>
                    <div className='flex flex-col px-3 text-center sm:px-6 justify-evenly md:px-12'>
                        <dt className='text-2xl font-semibold text-gray-700'>Total Hours</dt>
                        <dd className='text-4xl font-semibold text-gray-900'>{timeChunks.reduce((total, chunk) => (total += +chunk.hours), 0)}</dd>
                    </div>
                    <div>
                        <dl className='flex flex-wrap h-full pl-12'>
                            {Object.entries(totalsByUser(timeChunks))
                                .sort((a, b) => {
                                    return b[1] - a[1]
                                })
                                .map(([name, hours]) => (
                                    <div key={'dev-' + name} className='flex flex-col justify-between pr-2 text-center md:pr-4 lg:pr-8'>
                                        <dt className='text-lg font-semibold text-gray-700 capitalize'>{name}</dt>
                                        <dd className='text-2xl font-semibold text-gray-900'>
                                            {hours} <span className='text-sm text-gray-300'>{hours === 1 ? 'hr' : 'hrs'}</span>
                                        </dd>
                                    </div>
                                ))}
                        </dl>
                    </div>
                </div>
            </Card>

            {/* TimeChunks Feed */}
            <Card className='bg-white'>
                {timeChunks.length === 0 && (
                    <p leading-5 text-gray-500>
                        No tasks have been added
                    </p>
                )}
                <ul className='space-y-6'>
                    {timeChunks
                        .sort((a, b) => (moment(a).isBefore(b) ? 1 : -1))
                        .map((timeChunk, idx) => (
                            <li key={timeChunk.id} className='relative flex gap-x-4'>
                                <div className={classNames(idx === timeChunks.length - 1 ? 'h-8' : '-bottom-6', 'absolute left-0 top-0 flex w-8 justify-center')}>
                                    <div className='w-px bg-gray-200' />
                                </div>
                                {timeChunk.type === 'commented' ? (
                                    <>
                                        <img src={timeChunk.assignedTo.imageUrl} alt='' className='relative flex-none w-6 h-6 mt-3 rounded-full bg-gray-50' />
                                        <div className='flex-auto p-3 rounded-md ring-1 ring-inset ring-gray-200'>
                                            <div className='flex justify-between gap-x-4'>
                                                <div className='py-0.5 text-xs leading-5 text-gray-500'>
                                                    <span className='font-medium text-gray-900'>{timeChunk.assignedTo.name}</span> commented
                                                </div>
                                                <time dateTime={timeChunk.date} className='flex-none py-0.5 text-xs leading-5 text-gray-500'>
                                                    {timeChunk.date}
                                                </time>
                                            </div>
                                            <p className='text-sm leading-6 text-gray-500'>{timeChunk.comment}</p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='relative flex items-center justify-center flex-none w-8 h-8 bg-white'>{timeChunk.type === 'paid' ? <CheckCircleIcon className='w-8 h-8 text-indigo-600' aria-hidden='true' /> : <div className='h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300' />}</div>
                                        {/* Text Line */}
                                        <p className='flex-auto py-0.5 leading-5 text-gray-500'>
                                            <span className='font-medium text-gray-900'>{timeChunk.assignedTo.name}</span> added <span className='text-lg font-bold text-gray-900'>{timeChunk.hours}</span> hrs for <span className='font-medium text-gray-900'>{timeChunk.text}</span>
                                        </p>
                                        <time dateTime={timeChunk.date} className='flex-none py-0.5 text-xs leading-7 text-gray-500'>
                                            {moment(timeChunk.date).format('D MMM YY')}
                                        </time>
                                    </>
                                )}
                            </li>
                        ))}
                </ul>
            </Card>

            {/* Add new */}
            <Card className='bg-white'>
                <TimeTrackerForm onSubmit={(values) => createItem(values)}></TimeTrackerForm>
            </Card>
        </ContentWidthWrapper>
    )
}
