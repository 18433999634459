import { useEffect, useState } from 'react'

import Button from '../components/button'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'

import { api, api_download } from '../api.service'
import { SelectField } from './fields'

export default function CreateProjectForm() {
    const [boilerplates, setBoilerplates] = useState()
    const [selected, setSelected] = useState('')

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/boilerplate`).then((x) => setBoilerplates(x))
    }, [])

    function getBoilerplate(id) {
        if (id === '') {
            return
        }
        console.log('REQUEST SENT')
        api_download(`${process.env.REACT_APP_API_URL}/admin/generate-boilerplate/${id}`)
            .then((x) => {
                console.log(x)
                return x.read()
            })
            .then((x) => {
                console.log(x)
                console.log('DONE')
            })
    }

    if (!boilerplates) return <Loading></Loading>

    console.log(boilerplates)

    return (
        <>
            <PageHeader headline='Project Creator'></PageHeader>

            <section className='mx-4 space-y-4'>
                <SelectField
                    label='Select a Boilerplate'
                    value={selected}
                    options={boilerplates.map((x) => {
                        return { text: x.title, value: x._id }
                    })}
                    onChange={(val) => setSelected(val)}
                />
                <Button text='Download' onClick={() => getBoilerplate(selected)} />
            </section>
        </>
    )
}
