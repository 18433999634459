import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import SelectField from '../forms/fields/select-field'
import TextField from '../forms/fields/text-field'

import Button from './button'
import CodeEditor from './code-editor'

export default function DataSchemaBuilder() {
    const [data, setData] = useState({ models: [] })

    const onDragEnd = (result, v) => {
        let startingLane = +result.source.droppableId.split('_')[1]
        let modelProps = [...data.models[startingLane].fields]
        let moving = modelProps.splice(result.source.index, 1)
        modelProps.splice(result.destination.index, 0, moving[0])
        setData({ ...data, models: data.models.map((x, i) => (i == startingLane ? { ...x, fields: modelProps } : x)) })
    }

    function uncapitalizeFirstLetter(string) {
        return string?.charAt(0)?.toLowerCase() + string?.slice(1)
    }

    const getModelCode = (model) => {
        return `
import { Schema, model } from 'mongoose';

export interface I${model.name} {
    _id: Schema.Types.ObjectId;
    ${generateObjProperties(model.fields)}
}

const ${uncapitalizeFirstLetter(model.name)}Schema = new Schema<I${model.name}>({
    ${generateSchemaProperties(model.fields)}
});

const ${model.name} = model<I${model.name}>('${model.name}', ${uncapitalizeFirstLetter(model.name)}Schema);

export default ${model.name};`
    }

    const generateObjProperties = (fields) => {
        if (!fields) return ''
        let output = ''
        for (let f of fields) {
            output += `${f.name}: ${f.type};
    `
        }
        return output
    }
    const generateSchemaProperties = (fields) => {
        if (!fields) return ''

        let output = ''
        for (let f of fields) {
            output += `${f.name}: { type: ${f.type} },
    `
        }
        return output
    }

    return (
        <div className='flex h-screen p-5 space-x-4 overflow-y-scroll'>
            <DragDropContext onDragEnd={(e, v) => onDragEnd(e, v)}>
                {data.models?.map((x, i) => (
                    <div>
                        <div className='px-3 py-5 rounded shadow bg-slate-200 w-96'>
                            <TextField placeholder={'Model Name'} value={x.name} onChange={(val) => setData({ ...data, models: data.models.map((y, j) => (j === i ? { ...y, name: val } : y)) })}></TextField>
                            <Droppable droppableId={`lane_${i}`}>
                                {(provided, snapshot) => (
                                    <div className='min-h-[20px]' {...provided.droppableProps} ref={provided.innerRef}>
                                        {x.fields?.map((f, fi) => (
                                            <Draggable draggableId={i + '_' + fi} index={fi} key={i + '_' + fi}>
                                                {(provided, snapshot) => (
                                                    <div className='flex pt-1 pb-1 space-x-2 border' ref={provided.innerRef} {...provided.draggableProps}>
                                                        <div {...provided.dragHandleProps} className='w-2 mt-2 rounded-full bg-slate-800'></div>
                                                        <TextField placeholder={'Property'} className='flex-1' value={f.name} onChange={(val) => setData({ ...data, models: data.models.map((y, j) => (j === i ? { ...y, fields: y.fields.map((z, l) => (l === fi ? { ...z, name: val } : z)) } : y)) })} />
                                                        <SelectField
                                                            placeholder={'Type'}
                                                            className='flex-1'
                                                            options={[
                                                                { text: 'String', value: 'String' },
                                                                { text: 'Number', value: 'Number' },
                                                                { text: 'Object', value: 'Object' },
                                                                { text: 'Date', value: 'Date' },
                                                                { text: 'Boolean', value: 'Boolean' },
                                                            ]}
                                                            value={f.type}
                                                            onChange={(val) => setData({ ...data, models: data.models.map((y, j) => (j === i ? { ...y, fields: y.fields.map((z, l) => (l === fi ? { ...z, type: val } : z)) } : y)) })}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>

                            <Button text="Add Field" onClick={() => setData({ ...data, models: data.models.map((y, j) => (j === i ? { ...y, fields: y.fields ? y.fields.concat([{ type: 'String' }]) : [{ type: 'String' }] } : y)) })}>

                            </Button>
                            <div className="my-5"></div>
                            {x.fields[0].name && x.name && <>
                                <CodeEditor height={350} options={{ readOnly: true, minimap: { enabled: false } }} defaultLanguage='typescript' value={getModelCode(x)}></CodeEditor>

                            </>}
                        </div>
                    </div>

                ))}
            </DragDropContext>
            <div>
                <Button text="Add New Model" onClick={() => setData({ ...data, models: data.models.concat([{ fields: [{ type: 'String' }] }]) })} />
                {/* <div className='text-sm font-medium cursor-pointer text-neutral-800 hover:underline' onClick={() => setData({ ...data, models: data.models.concat([{}]) })}>
                    Add Model
                </div> */}
            </div>
        </div>
    )
}
