import { Route, Routes } from 'react-router-dom'

import './App.css'

import DataSchemaBuilder from './components/data-schema-builder'
import ScopeDocumentViewer from './components/scope-document-viewer'

import BoilerplateForm from './forms/boilerplate-form'
import CodeTemplateForm from './forms/code-template-form'
import ComponentForm from './forms/component-form'
import CreateProjectForm from './forms/create-project-form'
import ProjectForm from './forms/project-form'
import UserForm from './forms/user-form'

import Generate from './admin/admin-ai-assistant'
import AdminBoilerplate from './admin/admin-boilerplate'
import AdminCodeTemplate from './admin/admin-code-template'
import AdminComponents from './admin/admin-components'
import AdminDashboard from './admin/admin-dashboard'
import AdminDevTime from './admin/admin-dev-time'
import AdminGantt from './admin/admin-gantt'
import AdminLayout from './admin/admin-layout'
import AdminMarketingKanban from './admin/admin-marketing-kanban'
import AdminProject from './admin/admin-project'
import AdminProjects from './admin/admin-projects'
import AdminUsers from './admin/admin-users'
import Login from './auth/login'
import Logout from './auth/logout'
import Register from './auth/register'
import RequestReset from './auth/request-reset'
import ResetPassword from './auth/reset-password'
import SetPassword from './auth/set-password'
import Analytics from './client/analytics'
import AppLayout from './client/app-layout'
import Contract from './client/contract'
import Domain from './client/domain'
import ProjectDashboard from './client/project-dashboard'

export default function App() {
    return (
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/request-reset' element={<RequestReset />} />
            <Route path='/passwordReset' element={<ResetPassword />} />
            <Route path='/set-password' element={<SetPassword />} />
            <Route path='/logout' element={<Logout />} />

            <Route path='/app' element={<AppLayout />}>
                <Route index element={<ProjectDashboard></ProjectDashboard>} />
                <Route path='/app/scope' element={<ScopeDocumentViewer></ScopeDocumentViewer>} />
                <Route path='/app/domain' element={<Domain></Domain>} />
                <Route path='/app/analytics' element={<Analytics></Analytics>} />
                <Route path='/app/contract' element={<Contract></Contract>} />
            </Route>

            <Route path='/admin' element={<AdminLayout></AdminLayout>}>
                <Route path='/admin' element={<AdminDashboard></AdminDashboard>} />

                <Route path='/admin/users' element={<AdminUsers></AdminUsers>} />
                <Route path='/admin/manage-user/:id' element={<UserForm></UserForm>} />

                <Route path='/admin/boilerplate' element={<AdminBoilerplate></AdminBoilerplate>} />
                <Route path='/admin/manage-boilerplate/:id' element={<BoilerplateForm></BoilerplateForm>} />

                <Route path='/admin/code-template' element={<AdminCodeTemplate></AdminCodeTemplate>} />
                <Route path='/admin/manage-code-template/:id' element={<CodeTemplateForm></CodeTemplateForm>} />

                <Route path='/admin/component' element={<AdminComponents></AdminComponents>} />
                <Route path='/admin/manage-component/:id' element={<ComponentForm></ComponentForm>} />

                <Route path='/admin/projects' element={<AdminProjects></AdminProjects>} />
                <Route path='/admin/project/:id' element={<AdminProject></AdminProject>} />
                <Route path='/admin/manage-project/:id' element={<ProjectForm></ProjectForm>} />

                <Route path='/admin/gantt' element={<AdminGantt></AdminGantt>} />
                <Route path='/admin/time' element={<AdminDevTime></AdminDevTime>} />
                <Route path='/admin/data-schema' element={<DataSchemaBuilder></DataSchemaBuilder>} />
                <Route path='/admin/create-project' element={<CreateProjectForm></CreateProjectForm>} />
                <Route path='/admin/marketing-board' element={<AdminMarketingKanban></AdminMarketingKanban>} />

                <Route path='/admin/generate' element={<Generate />} />
            </Route>
        </Routes>
    )
}
