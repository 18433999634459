import { useEffect, useState } from 'react'

import Button from '../components/button'
import Loading from '../components/loading'

import { api } from '../api.service.js'
import TextField from './fields/text-field'

export default function FormWrapper({ url, id, callback, children, includeIdInPost, buttonText, hideButton, noStyle }) {
    const [values, setValues] = useState()

    useEffect(() => {
        if (id !== 'new')
            api(`${process.env.REACT_APP_API_URL}/${url}/${id}`).then((x) => {
                setValues(x ?? {})
            })
        else {
            setValues({})
        }
    }, [])

    const submit = (data) => {
        if (includeIdInPost) {
            api(`${process.env.REACT_APP_API_URL}/${url}/${id}`, { data: data ?? values }).then((x) => {
                callback()
            })
        } else {
            api(`${process.env.REACT_APP_API_URL}/${url}`, { data: data ?? values }).then((x) => {
                callback()
            })
        }
    }

    if (!values) return <Loading></Loading>

    return (
        <div className={noStyle ? '' : 'max-w-6xl mx-auto my-10 bg-white shadow-lg rounded-lg p-5'}>
            {children(values, setValues, submit)}

            {!hideButton && <Button className='my-10' text={buttonText ?? 'Submit'} onClick={() => submit()}></Button>}
        </div>
    )
}
