import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import useWebSocket from 'react-use-websocket'

import SelectField from '../forms/fields/select-field'
import TextAreaField from '../forms/fields/text-area-field'
import TextField from '../forms/fields/text-field'
import FormModal from '../forms/form-modal'

import { api } from '../api.service'

const lanes = ['Parked', 'To Do', 'In progress', 'Ongoing', 'Complete']

export default function AdminMarketingKanban({ id }) {
    const [tasks, setTasks] = useState()
    const [users, setUsers] = useState()
    const [cardEditModalId, setCardEditModalId] = useState()

    useEffect(() => {
        loadData()
        api(`${process.env.REACT_APP_API_URL}/admin/user`).then((x) => setUsers(x))
    }, [])

    const loadData = () => {
        api(`${process.env.REACT_APP_API_URL}/admin/tasks/marketing`).then((x) => setTasks(x))
    }

    const { sendMessage, lastMessage, readyState } = useWebSocket(process.env.REACT_APP_WS_URL)
    useEffect(() => {
        if (lastMessage?.data?.includes('tasks') && lastMessage.data?.includes('marketing')) {
            loadData()
        }
    }, [lastMessage])

    const onDragEnd = (result, v) => {
        let startingLane = result.source.droppableId.split('_')[1]
        let newLane = result.destination.droppableId.split('_')[1]
        moveCard(result.draggableId, newLane, result.destination.index)

        let _tasks = [...tasks]
        for (let [i, l] of lanes.entries()) {
            for (let [taskIndex, task] of _tasks
                .sort((a, b) => a.index - b.index)
                .filter((x) => x.lane == i)
                .entries()) {
                if (task._id == result.draggableId) {
                    task.lane = newLane
                    task.index = result.destination.index
                } else {
                    task.index = taskIndex
                    if (task.lane == startingLane && task.index >= result.source.index) {
                        task.index--
                    }
                    if (task.lane == newLane && task.index >= result.destination.index) {
                        task.index++
                    }
                }
            }
        }
        setTasks([...tasks.sort((a, b) => a.index - b.index)])
    }

    const upsertCard = (data) => {
        console.log(data)
        api(`${process.env.REACT_APP_API_URL}/admin/task/marketing`, { data })
    }
    const moveCard = (_id, lane, index) => {
        api(`${process.env.REACT_APP_API_URL}/admin/task/move/marketing/${_id}/${lane}/${index}`)
    }

    const openEditModal = (id) => {
        setCardEditModalId(id)
    }

    return (
        <>
            <FormModal open={!!cardEditModalId} setOpen={() => setCardEditModalId(null)}>
                <div className='grid grid-cols-2 gap-2'>
                    <TextField
                        label='Title'
                        value={tasks?.find((x) => x._id == cardEditModalId)?.title}
                        onChange={(val) => {
                            setTasks(tasks.map((x) => (x._id == cardEditModalId ? { ...x, title: val } : x)))
                        }}
                        onBlur={() => upsertCard(tasks.find((x) => x._id == cardEditModalId))}
                    />
                    <SelectField
                        optionListName={'users'}
                        label='Assigned To'
                        value={tasks?.find((x) => x._id == cardEditModalId)?.assignedTo?._id}
                        onChange={(val) => {
                            setTasks(tasks.map((x) => (x._id == cardEditModalId ? { ...x, assignedTo: val } : x)))
                            upsertCard({ ...tasks.find((x) => x._id == cardEditModalId), assignedTo: val })
                        }}
                    />
                </div>
                <TextAreaField
                    label='Text'
                    value={tasks?.find((x) => x._id == cardEditModalId)?.text}
                    onChange={(val) => {
                        setTasks(tasks.map((x) => (x._id == cardEditModalId ? { ...x, text: val } : x)))
                    }}
                    onBlur={() => upsertCard(tasks.find((x) => x._id == cardEditModalId))}
                />
                <TextAreaField
                    label='Details'
                    value={tasks?.find((x) => x._id == cardEditModalId)?.details}
                    onChange={(val) => {
                        setTasks(tasks.map((x) => (x._id == cardEditModalId ? { ...x, details: val } : x)))
                    }}
                    onBlur={() => upsertCard(tasks.find((x) => x._id == cardEditModalId))}
                />
            </FormModal>

            <div className='flex h-screen p-5 space-x-4 overflow-y-scroll'>
                <DragDropContext onDragEnd={(e, v) => onDragEnd(e, v)}>
                    {lanes.map((lane, laneId) => (
                        <div className='px-3 py-5 rounded shadow bg-slate-200 w-96'>
                            <div className='font-semibold tracking-tighter select-none text-neutral-800'>{lane}</div>

                            <Droppable droppableId={`lane_${laneId}`}>
                                {(provided, snapshot) => (
                                    <div className='min-h-[20px]' {...provided.droppableProps} ref={provided.innerRef}>
                                        {tasks
                                            ?.filter((x) => x.lane == laneId || (!x.lane && laneId == 0))
                                            .sort((a, b) => a.index - b.index)
                                            .map((x, i) => (
                                                <Draggable draggableId={x._id} index={i} key={x._id}>
                                                    {(provided, snapshot) => (
                                                        <div className='py-2' onClick={() => openEditModal(x._id)} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className='flex items-baseline justify-between p-2 text-sm font-medium select-none rounded-t-md bg-slate-300 text-neutral-700'>
                                                                <div className='truncate'>{x.title}</div>
                                                                {x.assignedTo?.name && <div className='p-1 px-2 text-xs font-semibold text-white capitalize bg-green-500 rounded-full'>{x.assignedTo?.name[0]}</div>}
                                                            </div>
                                                            <div className={`bg-neutral-50 tracking-wide rounded-b-md p-2 text-xs text-neutral-700 select-none`}>{x.text}</div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>

                            <div
                                className='font-medium cursor-pointer text-slate-600 hover:underline'
                                onClick={() => {
                                    upsertCard({ title: 'New Card', lane: laneId, index: 0 })
                                }}
                            >
                                Add a task...
                            </div>
                        </div>
                    ))}
                </DragDropContext>
            </div>
        </>
    )
}
